import React, { useState, useEffect } from "react";
import { getAuth, sendPasswordResetEmail, updatePassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import logo from "../../assets/images/logo.png";

const ResetPwd = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [warn, setWarn] = useState("");
  const [email, setEmail] = useState("");

  const newWarn = (value) => {
    setWarn(value);
    setTimeout(() => {
      setWarn("");
    }, 3000);
  };

  // Redirect to home if user is logged in
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/", { replace: true });
      }
    });
    return () => {
      listen();
    };
  }, []);

  const reset = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        newWarn("Password Reset Email Sent!");
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        newWarn(errorMessage);

        // ..
      });
  };
  return (
    <div className="signin-page mt-5 p-4">
      <form onSubmit={reset}>
        <center>
          <img src={logo} className="app-logo-sm" alt="logo" />
          <h1 className="mt-2">Reset Password</h1>
        </center>
        {warn && (
          <div className="alert alert-info mt-1" role="alert">
            {warn}
          </div>
        )}
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            required
          />
        </div>

        <button type="submit" className="btn btn-signup mt-3">
          Send
        </button>
      </form>
    </div>
  );
};

export default ResetPwd;
