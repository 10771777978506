import React from "react";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="container-md">
      <h3
        style={{
          height: "300px",
          width: "100%",
          fontSize: "4rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {t("Coming Soon")}
      </h3>
      {/* <h3 >Our Courses</h3> */}
      <div
        className="container"
        style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
      ></div>
    </div>
  );
};

export default Services;
