import React, { useState, useEffect } from "react";
import "./Status.css";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const Status = ({ userPAN }) => {
  const [statusData, setStatusData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(-1); // Index of last active status

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { userPAN: pan } = userPAN;
        const currentYear = process.env.REACT_APP_CURRENT_YEAR;
        const statusRef = doc(db, `${currentYear}`, pan);
        const docSnap = await getDoc(statusRef);

        if (docSnap.exists()) {
          const statusObject = docSnap.data().status;
          if (statusObject && typeof statusObject === "object") {
            const statusArray = Object.entries(statusObject)
              .filter(([_, value]) => value === true)
              .map(([key]) => key);
            setStatusData(statusArray);
            const lastIndex = statusArray.length - 1;
            setActiveIndex(lastIndex);
          } else {
            console.log("Status field is not an object");
            setStatusData([]);
            setActiveIndex(-1);
          }
        } else {
          console.log("Document does not exist!");
          setStatusData([]);
          setActiveIndex(-1);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching status data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [userPAN]);

  const statusValues = [
    "Manager Assigned",
    "ITR Password Generated",
    "Data Entered",
    "Data Punched",
    "Verification",
    "ITR Filled",
    "ITR Pending",
    "Refund Processed",
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  // Determine the indices of the statuses to display
  const indicesToDisplay = [];
  if (activeIndex >= 0) {
    // Display last active status
    indicesToDisplay.push(activeIndex);

    // Display status before the last active status
    const beforeLastActiveIndex = activeIndex - 1;
    if (beforeLastActiveIndex >= 0) {
      indicesToDisplay.push(beforeLastActiveIndex);
    }

    // Display status after the last active status
    const afterLastActiveIndex = activeIndex + 1;
    if (afterLastActiveIndex < statusValues.length) {
      indicesToDisplay.push(afterLastActiveIndex);
    }
  }

  return (
    <div className="status-container">
      <div className="status-line p-bt-2">
        {statusValues.map((status, index) => {
          if (!indicesToDisplay.includes(index)) {
            return null; // Hide status if not in indicesToDisplay
          }
          return (
            <div key={status} className="status-item">
              <div
                className={`status-circle ${statusData.includes(status) ? "active" : ""
                }`}
              >
                {statusData.includes(status) && <span className="tick-mark">✓</span>}
              </div>
              <span className="status-label">{status}</span>
            </div>
          );
        })}
      </div>
      <div className="status-connector"></div>
    </div>
  );
};

export default Status;