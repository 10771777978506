import { React, useState, useEffect } from "react";
import Modal from "react-modal";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { auth } from "../firebase";
import scan from "../assets/images/add-image.png";
import { useTranslation } from "react-i18next";
// import { ClampToEdgeWrapping } from "three";
// import { use } from "i18next";
// import { set } from "ol/transform";

Modal.setAppElement("#root");

const Question = ({
  showUpload = null,
  isNBSP = false,
  questID = null,
  question = [],
  onAnswer = null,
  onPrev = null,
  onSubmit = null,
  state: [value = [], setValue],
  radio = false,
  options = [],
  loading = false,
  label = [],
  suggestion = true,
  dropdown = null,
}) => {
  const uid = auth.currentUser?.uid;
  // alert(uid);
  const year = process.env.REACT_APP_CURRENT_YEAR;
  const [nbspURL0, setNBSPURL0] = useState(null);
  const [nbspURL1, setNBSPURL1] = useState(null);
  const { t } = useTranslation();
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);

  const email = auth.currentUser?.email;
  const [pan, setPan] = useState("");
  // console.log(pan);
  // alert(Number(questID) === 11);

  const getPan = (email) => {
    if (email) {
      let temppan = email.split("+");
      temppan = temppan[1].split("@");
      return temppan[0];
    }
    return null;
  };

  const handleFileUpload = async () => {
    const fileNames = {
      0: "gross",
      1: "Income from other sources",
      2: "80C",
      3: "Income from other sources",
      4: "80C",
      5: "80CCD",
      6: "80D",
      7: "80U",
      8: "80D(80DDB)",
      9: "80G",
      10: "80GG",
      11: "16B",
    };
    const id = Number(questID);
    // alert();

    if (selectedFile && uid) {
      setIsLoading(true);
      const fileName = selectedFile.name;
      const storageRef = ref(
        storage,
        `docs/${[uid]}/${year}/${fileNames[id] + " " + id}`
      );
      try {
        await uploadBytes(storageRef, selectedFile);
        const fileUrl = await getDownloadURL(storageRef);

        // Store the uploaded file URL
        setUploadedFileUrl(fileUrl);

        setIsLoading(false);
        togglePopup();
      } catch (error) {
        console.error("Error uploading file: ", error);
        alert("Error uploading file: ", error);
        setIsLoading(false);
      }
    }
  };

  const [message, setmessage] = useState("");
  const newmessage = (value) => {
    setmessage(value);
    setTimeout(() => {
      setmessage("");
    }, 3000);
  };
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // fileName
    setSelectedFile(file);
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  async function checkFileExists(filePath) {
    // const fileRef = storage.ref().child(filePath);
    try {
      await getDownloadURL(filePath);
      return true;
    } catch (error) {
      if (error.code === "storage/object-not-found") {
        return false; // File doesn't exist
      }
    }
  }

  const [fileUrll, setFileUrl] = useState(null);
  const fileLink = async () => {
    const fileNames = {
      0: "gross",
      1: "Income from other sources",
      2: "80C",
      3: "Income from other sources",
      4: "80C",
      5: "80CCD",
      6: "80D",
      7: "80U",
      8: "80D(80DDB)",
      9: "80G",
      10: "80GG",
      11: "16B",
    };
    const id = Number(questID);
    //console.log(Pan);
    if (isNBSP) {
      // update nbspURL array
      const storageRef1 = ref(storage, `docs/${pan}/${year}/${0}`);
      const storageRef = ref(storage, `docs/${pan}/${year}/${4}`);
      const fileUrl = await getDownloadURL(storageRef);
      checkFileExists(storageRef1)
        .then(async (exists) => {
          // console.log(`File exists: ${exists}`);
          const fileUrl1 = await getDownloadURL(storageRef1);
          setNBSPURL0(fileUrl1);
          return;
        })
        .catch((error) => { });
      checkFileExists(storageRef)
        .then(async (exists) => {
          // console.log(`File exists: ${exists}`);
          const fileUrl = await getDownloadURL(storageRef);
          setNBSPURL1(fileUrl);
          return;
        })
        .catch((error) => { });
    } else {
      // if not nbsp
      const storageRef = ref(
        storage,
        `docs/${pan}/${year}/${fileNames[id] + " " + id}`
      );
      checkFileExists(storageRef)
        .then(async (exists) => {
          // console.log(`File exists: ${exists}`);
          const fileUrl = await getDownloadURL(storageRef);
          setFileUrl(fileUrl);
          return;
        })
        .catch((error) => {
          // console.log("File exists: false");
        });

      return;
    }
  };
  useEffect(() => {
    fileLink();
  }, []);

  useEffect(() => {
    setPan(getPan(auth.currentUser?.email));
  }, [auth, pan]);

  // const handleFileUpload = async () => {
  //   if (selectedFile && uid) {
  //     setIsLoading(true);
  //     const fileName = selectedFile.name;
  //     // console.log(questID);
  //     const storageRef = ref(storage, `docs/${uid}/${year}/${questID}`);
  //     try {
  //       await uploadBytes(storageRef, selectedFile);
  //       const fileUrl = await getDownloadURL(storageRef);
  //       if (questID == 0) {
  //         setNBSPURL0(fileUrl);
  //       } else if (questID == 4) {
  //         setNBSPURL1(fileUrl);
  //       } else {
  //         setFileUrl(fileUrl);
  //       }

  //       // console.log("Document updated successfully!");
  //       setIsLoading(false);
  //       togglePopup();
  //     } catch (error) {
  //       console.error("Error uploading file: ", error);
  //       alert("Error uploading file: ", error);
  //       setIsLoading(false);
  //     }
  //   }
  // };

  return (
    <div className="question" style={{ flexDirection: "column" }}>
      {message && (
        <div className="alert alert-success quest-alert" style={{ margin: 0 }}>
          {message}
        </div>
      )}
      <div className="question-content px-2" style={{ maxWidth: "600px" }}>
        {question.map((item, index) => (
          <h3 key={index} className="m-2 mb-4">
            {item}
          </h3>
        ))}
        {suggestion && (
          <div className="suggestion">
            <button
              onClick={() => {
                newmessage("Great, now enter the amount in the respective box");
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                onSubmit();
              }}
            >
              No
            </button>
          </div>
        )}
        {dropdown && (
          <div className="dropdown">
            <label htmlFor={dropdown.label}>{dropdown.label}</label>
            <select
              className="dropdown"
              id={dropdown.label}
              value={dropdown.value}
              onChange={(e) => dropdown.onChange(e.target.value)}
            >
              <option value="">Select an option</option>
              {dropdown.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
        {!radio &&
          value.map((item, index) => (
            <input
              className="form-control my-2"
              type="text"
              value={item}
              placeholder={label[index]}
              autoFocus={index === 0 ? true : false}
              onChange={(e) => {
                let temp = [...value];
                temp[index] = e.target.value;
                // console.log("temp:", temp[index]);
                let y = temp[index].split(",").join("");
                // console.log("y", y);
                if (+y || +y === 0) {
                  let x = (+y).toLocaleString("en-IN");
                  // console.log("x", x);
                  temp[index] = x;
                  setValue(temp);
                  // console.log("Value ", value);}
                }
              }}
            />
          ))}

        {radio && (
          <div className=" radio-questions">
            {" "}
            {options.map((item, index) => (
              <label>
                <input
                  className="my-2"
                  type="radio"
                  name={question[0]}
                  value={index}
                  onClick={() => {
                    setValue([index]);
                  }}
                  defaultChecked={index === value ? true : false}
                />
                {item}
                {/* {console.log("value", value)} */}
              </label>
            ))}
          </div>
        )}
        {onAnswer ? (
          <button
            className="btn btn-next btn-primary m-2"
            onClick={() => {
              onAnswer();
            }}
          >
            {t("Next")}
          </button>
        ) : null}
        {/* // Submit button */}
        {/* {showUpload ? (
          <p>
            <span style={{ color: "#ff0000" }}>
              Make Sure To Upload The Proof
            </span>{" "}
            and don&apos;t reload page  
          </p>
        ) : null} */}
        {onSubmit ? (
          <button
            disabled={loading}
            className="btn btn-next btn-primary m-2"
            onClick={() => {
              onSubmit();
            }}
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        ) : null}
        {onPrev ? (
          <button className="btn btn-next btn-primary m-2" onClick={onPrev}>
            Previous
          </button>
        ) : null}
      </div>
      {showUpload ? (
        <>
          <div>
            <img
              src={scan}
              className="scanner-icon"
              onClick={togglePopup}
              alt="Scan"
            />
            {/* Display the uploaded file as a preview */}
            {uploadedFileUrl && (
              <img
                src={uploadedFileUrl}
                alt="Preview"
                style={{ maxWidth: "5rem" }}
              />
            )}
          </div>

          {/* // which updload to show based on questID */}
          {/* {questID == 0 || questID == 4 || !isNBSP ? (
            <a
              href={
                questID == 0 ? nbspURL0 : questID == 4 ? nbspURL1 : fileUrll
              }
              target="_blank"
              rel="noreferrer"
            >
              Preview
            </a>
          ) : null} */}
          {/* {questID == 0 && nbspURL0 ? (
            <a href={nbspURL0} target="_blank" rel="noreferrer">
              Preview
            </a>
          ) : null}
          {questID == 4 && nbspURL1 ? (
            <a href={nbspURL1} target="_blank" rel="noreferrer">
              Preview
            </a>
          ) : null}
          {!isNBSP && fileUrll ? (
            <a href={fileUrll} target="_blank" rel="noreferrer">
              Preview
            </a>
          ) : null} */}
          <Modal
            isOpen={isPopupOpen}
            onRequestClose={togglePopup}
            contentLabel="Upload Popup"
            style={{
              content: {
                width: "60%",
                height: "30vh",
                margin: "auto",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -70%)",
              },
            }}
          >
            <div className="modal-content">
              <h2 className="modal-title">Upload File</h2>&nbsp;
              <div className="modal-body d-flex flex-column ">
                <div className="mb-4">
                  {/* <label htmlFor="formFileLg" className="form-label">
                    Large file input example
                  </label> */}
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={handleFileChange}
                  />
                </div>
                {/* <input type="file" className="file-input" /> */}
                <div className="button-group d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={handleFileUpload}
                    disabled={isLoading}
                  >
                    {isLoading ? "Uploading..." : "Upload"}
                  </button>

                  <button
                    className="cancel-button btn btn-secondary"
                    onClick={togglePopup}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      ) : null}
    </div>
  );
};

export default Question;
