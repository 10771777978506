// authUtils.js

import { onAuthStateChanged } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import { auth, db } from "../../firebase";

export const checkUserPayment = async (user, navigate) => {
  if (user) {
    const match = user.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    const currentYear = process.env.REACT_APP_CURRENT_YEAR;
    const docRef = doc(db, currentYear.toString(), pan);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (docSnap.data()["paymentRequired"] === true) {
        return navigate("/dashboard", { replace: true });
      }
      
    } else {
      // Handle the case when the user document doesn't exist
      return navigate("/error", { replace: true });
    }
  } else {
    return navigate("auth/signIn", { replace: true });
  }
};

export const useAuthListener = (navigate) => {
  return onAuthStateChanged(auth, async (user) => {
    if (user) {
      await checkUserPayment(user, navigate);
    } else {
      // Corrected the navigation path
      navigate("/auth/signIn", { replace: true });
    }
  });
};
