import {
  SignIn,
  SignUp,
  Language,
  Payment,
  ResetPwd,
  ChangePwd,
} from "./components";
import Error from "./components/error";
import { Course, Landing, Home, Profile, Questions, Test } from "./pages";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import AuthDetails from "./components/auth/AuthDetails";
import {
  BrowserRouter,
  Routes,
  Route,
  // Navigate
} from "react-router-dom";
// import { auth } from "./firebase";
// import { useEffect, useState } from "react";
// import { onAuthStateChanged } from "firebase/auth";

// admin side pages
import React from "react";
// import { Suspense } from "react";
// import VerifyEmail from "./components/auth/EmailVerification";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queries: {
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24,
      },
    },
  },
});

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={(props) =>{
//         const listen = onAuthStateChanged(auth, async (user) => {
//           if (user) {
//             <Component {...props} />
//           } else {
//             <Navigate to="/login" />
//           }
//         });
//       }
//       }
//     />
//   );
// };

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Language />
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/test" element={<Test />} />
          <Route exact path="/dashboard" element={<Home />} />
          <Route path="course" element={<Course />} />
          <Route path="profile" element={<Profile />} />
          <Route path="questions/:quesId/:year" element={<Questions />} />
          {/* <Route path="payment" element={<Payment />} /> */}
          <Route path="auth/signin" element={<SignIn />} />
          <Route path="auth/reset" element={<ResetPwd />} />
          {/* <Route path="auth/signup" element={<SignUp />} /> */}
          <Route path="auth/change_pwd" element={<ChangePwd />} />
          {/* <Route path="auth/signup" element={<SignUp />} /> */}
          <Route path="auth/change_pwd" element={<ChangePwd />} />
          <Route path="/error" element={<Error />} />
          {/* <Route path="auth/logout" element={<AuthDetails />} /> */}
          {/* <Route path="auth/verify_email" element={<VerifyEmail />} /> */}
        </Routes>
      </QueryClientProvider>
    </BrowserRouter>
  );
  // const props = "user";
  // if (props === "admin") {
  //   return (
  //     <Suspense fallback={<div>Loading</div>}>
  //       <Admin />
  //     </Suspense>
  //   );
  // } else {
  //   return (
  //     <BrowserRouter>
  //       <QueryClientProvider client={queryClient}>
  //         <Language />
  //         <Routes>
  //           <Route exact path="/" element={<Landing />} />
  //           <Route exact path="/dashboard" element={<Home />} />
  //           <Route path="course" element={<Course />} />
  //           <Route path="auth/signin" element={<SignIn />} />
  //           <Route path="auth/reset" element={<ResetPwd />} />
  //           <Route path="auth/signup" element={<SignUp />} />
  //           <Route path="profile" element={<Profile />} />
  //           <Route path="auth/logout" element={<AuthDetails />} />
  //           <Route path="questions" element={<Questions />} />
  //         </Routes>
  //       </QueryClientProvider>
  //     </BrowserRouter>
  //   );
  // }
}

export default App;
