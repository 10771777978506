import { React, useState, useEffect } from "react";
import { auth } from "../firebase";

const UserCard = ({ userName, userPAN }) => {
  const authUser = auth.currentUser;
  const [photoUrl, setPhotoUrl] = useState("https://tinyurl.com/4ypa9c6z");

  useEffect(() => {
    if (authUser?.photoURL) {
      setPhotoUrl(authUser.photoURL);
    }
  }, [authUser]);

  return (
    <div className="text-center mt-5">
      <img
        src={photoUrl}
        className="rounded-circle object-fit-cover"
        alt="user avatar"
        width="100px"
        height="100px"
        style={{objectFit:"cover"}}
        // onChange={handleSelect}
      ></img>
      <p className="mt-3">
        <b>{userName}</b> | {userPAN}
      </p>
    </div>  
  );
};

export default UserCard;
