/* eslint-disable quotes */
import React from "react";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { saveAs } from "file-saver";
import statePdf from "./pdfs/PartA.pdf";
const { toWords } = require("number-to-words");

async function PartApdf(userObject, QuarterObj) {
  console.log("quarter obj is:", QuarterObj);
  console.log("userObject is", userObject);
  let statusApr = "F";
  let statusMay = "F";
  let statusJun = "F";
  let statusJul = "F";
  let statusAug = "F";
  let statusSep = "F";
  let statusOct = "F";
  let statusNov = "F";
  let statusDec = "F";
  let statusJan = "F";
  let statusFeb = "F";
  let statusMar = "F";

  const url = statePdf;
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
  // const existingPdfBytes = fs.readFileSync(filePath);
  let X = 827;
  let Y = 1169;

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  // const { width, height } =    .getSize();
  // console.log(width, height);

  let zeroth_column = 245;
  let first_column = 330;
  let second_column = 410;
  let third_column = 490;

  let dist = 13;
  let base = 728;

  function formatRupees(number) {
    // Convert the number to a string
    let numStr = number.toString();
    // Split the string into integer and decimal parts
    let parts = numStr.split(".");
    // Format the integer part with commas for thousands separator only
    parts[0] = parts[0].replace(/(\d)(?=(\d{2})+\d$)/g, "$1,");
    // Join the parts back together with a comma if there is a decimal part
    return parts.length === 1 ? parts[0] : parts.join(".");
  }

  function getFormattedDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  }

  function numberToWords(num) {
    const words = toWords(num).toLowerCase().split(" ");
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(" "); // Convert to uppercase for better readability
  }

  if (QuarterObj.aprTax === 0) {
    QuarterObj.recNoApr = '-';
    QuarterObj.DDOApr = '-';
    QuarterObj.dateApr = '-';
    statusApr = '-';
  }

  if (QuarterObj.mayTax === 0) {
    QuarterObj.recNoMay = '-';
    QuarterObj.DDOMay = '-';
    QuarterObj.dateMay = '-';
    statusMay = '-';
  }
  if (QuarterObj.junTax === 0) {
    QuarterObj.recNoJun = '-';
    QuarterObj.DDOJun = '-';
    QuarterObj.dateJun = '-';
    statusJun = '-';
  }
  if (QuarterObj.julTax === 0) {
    QuarterObj.recNoJul = '-';
    QuarterObj.DDOJul = '-';
    QuarterObj.dateJul = '-';
    statusJul = '-';
  }
  if (QuarterObj.augTax === 0) {
    QuarterObj.recNoAug = '-';
    QuarterObj.DDOAug = '-';
    QuarterObj.dateAug = '-';
    statusAug = '-';
  }
  if (QuarterObj.sepTax === 0) {
    QuarterObj.recNoSep = '-';
    QuarterObj.DDOSep = '-';
    QuarterObj.dateSep = '-';
    statusSep = '-';
  }
  if (QuarterObj.octTax === 0) {
    QuarterObj.recNoOct = '-';
    QuarterObj.DDOOct = '-';
    QuarterObj.dateOct = '-';
    statusOct = '-';
  }
  if (QuarterObj.novTax === 0) {
    QuarterObj.recNoNov = '-';
    QuarterObj.DDONov = '-';
    QuarterObj.dateNov = '-';
    statusNov = '-';
  }
  if (QuarterObj.decTax === 0) {
    QuarterObj.recNoDec = '-';
    QuarterObj.DDODec = '-';
    QuarterObj.dateDec = '-';
    statusDec = '-';
  }
  if (QuarterObj.janTax === 0) {
    QuarterObj.recNoJan = '-';
    QuarterObj.DDOJan = '-';
    QuarterObj.dateJan = '-';
    statusJan = '-';
  }
  if (QuarterObj.febTax === 0) {
    QuarterObj.recNoFeb = '-';
    QuarterObj.DDOFeb = '-';
    QuarterObj.dateFeb = '-';
    statusFeb = '-';
  }
  if (QuarterObj.marTax === 0) {
    QuarterObj.recNoMar = '-';
    QuarterObj.DDOMar = '-';
    QuarterObj.dateMar = '-';
    statusMar = '-';
  }

  const currentYear = parseInt(userObject.year.split('-')[0]);
  const prevYear = currentYear - 1;

  // -------------------- Section 0 (Header) Starts -------------------------------------
  base = 805;
  //console.log("current year is", userObject.year);
  firstPage.drawText(userObject.year, {
    x: 46,
    y: base,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  base = 743;
  firstPage.drawText(userObject.name, {
    x: 300,
    y: base,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(userObject.Designation, {
    x: 300,
    y: 730,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  base = 742;
  firstPage.drawText(userObject.addressLine1, {
    x: 30,
    y: base,
    size: 7,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  base = 730;
  firstPage.drawText(userObject.addressLine2, {
    x: 30,
    y: base,
    size: 7,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  base = 688;
  firstPage.drawText(userObject.pan, {
    x: 460,
    y: base,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(userObject.tan, {
    x: 295,
    y: base,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  // -------------------- assesment year -------------------------------------
  base = 630;
  firstPage.drawText(userObject.year, {
    x: 340,
    y: base,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  const prevYearStart = `1-Apr-${prevYear}`;
  const prevYearEnd = `31-Mar-${currentYear}`;

  firstPage.drawText(prevYearStart, {
    x: 430,
    y: base,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(prevYearEnd, {
    x: 500,
    y: base,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  // -------------------- Summary of amount paid/credited -------------------------------------

  let base1 = 543;
  let base2 = 528;
  let base3 = 514;
  let base4 = 500;
  let baseTotal = 484;
  let x_val = 343;

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.sumQ1)}`, {
    x: x_val,
    y: base1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.sumQ2)}`, {
    x: x_val,
    y: base2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.sumQ3)}`, {
    x: x_val,
    y: base3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.sumQ4)}`, {
    x: x_val,
    y: base4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.taxDeductedSum)}`, {
    x: x_val,
    y: baseTotal,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  //amount of tax deposited
  x_val = 460;

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.sumQ1)}`, {
    x: x_val,
    y: base1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.sumQ2)}`, {
    x: x_val,
    y: base2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.sumQ3)}`, {
    x: x_val,
    y: base3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.sumQ4)}`, {
    x: x_val,
    y: base4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.taxDeductedSum)}`, {
    x: x_val,
    y: baseTotal,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  x_val = 227;
  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.salaryQ1)}`, {
    x: x_val,
    y: base1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.salaryQ2)}`, {
    x: x_val,
    y: base2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.salaryQ3)}`, {
    x: x_val,
    y: base3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.salaryQ4)}`, {
    x: x_val,
    y: base4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.salaryTotal)}`, {
    x: x_val,
    y: baseTotal,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });


  x_val = 63
  firstPage.drawText(QuarterObj.ReceiptNoQ1, {
    x: x_val,
    y: base1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.ReceiptNoQ2, {
    x: x_val,
    y: base2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.ReceiptNoQ3, {
    x: x_val,
    y: base3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.ReceiptNoQ4, {
    x: x_val,
    y: base4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });


  //---------------------------DETAILS OF TAX DEDUCTED------------------------------------------


  base1 = 393;
  base2 = 378;
  base3 = 365;
  base4 = 350;
  let base5 = 337;
  let base6 = 324;
  let base7 = 309;
  let base8 = 295;
  let base9 = 282;
  let base10 = 267;
  let base11 = 253;
  let base12 = 240;
  baseTotal = 226;

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.aprTax)}`, {
    x: x_val,
    y: base1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.mayTax)}`, {
    x: x_val,
    y: base2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.junTax)}`, {
    x: x_val,
    y: base3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.julTax)}`, {
    x: x_val,
    y: base4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.augTax)}`, {
    x: x_val,
    y: base5,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.sepTax)}`, {
    x: x_val,
    y: base6,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.octTax)}`, {
    x: x_val,
    y: base7,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.novTax)}`, {
    x: x_val,
    y: base8,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.decTax)}`, {
    x: x_val,
    y: base9,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.janTax)}`, {
    x: x_val,
    y: base10,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.febTax)}`, {
    x: x_val,
    y: base11,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.marTax)}`, {
    x: x_val,
    y: base12,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs  ${formatRupees(QuarterObj.taxTotal)}`, {
    x: x_val,
    y: baseTotal,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  //-----------------------------------------second coloumn--------------------------------
  x_val = 155;
  firstPage.drawText(QuarterObj.recNoApr, {
    x: x_val,
    y: base1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoMay, {
    x: x_val,
    y: base2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoJun, {
    x: x_val,
    y: base3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoJul, {
    x: x_val,
    y: base4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoAug, {
    x: x_val,
    y: base5,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoSep, {
    x: x_val,
    y: base6,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoOct, {
    x: x_val,
    y: base7,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoNov, {
    x: x_val,
    y: base8,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoDec, {
    x: x_val,
    y: base9,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoJan, {
    x: x_val,
    y: base10,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoFeb, {
    x: x_val,
    y: base11,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.recNoMar, {
    x: x_val,
    y: base12,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  //-------------------------------------THIRD COLUMN------------------------------------
  x_val = 265;
  firstPage.drawText(QuarterObj.DDOApr, {
    x: x_val,
    y: base1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDOMay, {
    x: x_val,
    y: base2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDOJun, {
    x: x_val,
    y: base3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDOJul, {
    x: x_val,
    y: base4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDOAug, {
    x: x_val,
    y: base5,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDOSep, {
    x: x_val,
    y: base6,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDOOct, {
    x: x_val,
    y: base7,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDONov, {
    x: x_val,
    y: base8,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDODec, {
    x: x_val,
    y: base9,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDOJan, {
    x: x_val,
    y: base10,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDOFeb, {
    x: x_val,
    y: base11,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.DDOMar, {
    x: x_val,
    y: base12,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  //------------------------------------FOURTH COLUMN------------------------------------

  x_val = 375;
  firstPage.drawText(QuarterObj.dateApr, {
    x: x_val,
    y: base1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateMay, {
    x: x_val,
    y: base2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateJun, {
    x: x_val,
    y: base3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateJul, {
    x: x_val,
    y: base4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateAug, {
    x: x_val,
    y: base5,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateSep, {
    x: x_val,
    y: base6,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateOct, {
    x: x_val,
    y: base7,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateNov, {
    x: x_val,
    y: base8,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateDec, {
    x: x_val,
    y: base9,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateJan, {
    x: x_val,
    y: base10,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateFeb, {
    x: x_val,
    y: base11,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(QuarterObj.dateMar, {
    x: x_val,
    y: base12,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  //---------------------------------------------FIFTH column-------------------------
  x_val = 505;
  firstPage.drawText(statusApr, {
    x: x_val,
    y: base1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusMay, {
    x: x_val,
    y: base2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusJun, {
    x: x_val,
    y: base3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusJul, {
    x: x_val,
    y: base4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusAug, {
    x: x_val,
    y: base5,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusSep, {
    x: x_val,
    y: base6,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusOct, {
    x: x_val,
    y: base7,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusNov, {
    x: x_val,
    y: base8,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusDec, {
    x: x_val,
    y: base9,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusJan, {
    x: x_val,
    y: base10,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusFeb, {
    x: x_val,
    y: base11,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(statusMar, {
    x: x_val,
    y: base12,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  //---------------------------VERIFICATION--------------------------------------------------------

  firstPage.drawText(userObject.name, {
    x: 30,
    y: 188,
    size: 8,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`${getFormattedDate()}`, {
    x: 70,
    y: 104,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`${formatRupees(QuarterObj.taxTotal)}`, {
    x: 200,
    y: 174,
    size: 8,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`${numberToWords(QuarterObj.taxTotal)}`, {
    x: 275,
    y: 174,
    size: 8,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(userObject.name, {
    x: 170,
    y: 75,
    size: 10,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(userObject.Designation, {
    x: 170,
    y: 62,
    size: 10,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });







  // console.log(`hello`)
  const pdfBytes = await pdfDoc.save();

  const pdfBlob = new Blob([pdfBytes], { type: `application/pdf` });

  // Save the PDF file using FileSaver.js
  saveAs(pdfBlob, `TS-Form16A.pdf`);
}


export default PartApdf;
