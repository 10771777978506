/* eslint-disable react-hooks/exhaustive-deps */
import { onAuthStateChanged } from "firebase/auth";
import { React, useState, useEffect, useCallback, useContext } from "react";
import { EditButton, SubmitButton } from "../components/buttons";
import { Link } from "react-router-dom";
import { signOut, updateProfile } from "firebase/auth";
import { auth, db, storage } from "../firebase";
import ErrorBoundary from "../ErrorBoundary";
import { useUser } from "../data";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useTranslation } from "react-i18next";
import { raiseQuery } from "../data/query";
import { collection, query, where, getDocs } from "firebase/firestore";


const Profile = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authUser = auth.currentUser;
  const userData = useUser();
  const [edit, setEdit] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [policeStation, setPoliceStation] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [Designation, setDesignation] = useState("");
  const [TAN, setTAN] = useState("");
  const [UserPan, setUserPan] = useState(props.pan);

  const [officeAddress, setOfficeAddress] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [roleSet, setRoleSet] = useState("");
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("https://tinyurl.com/4ypa9c6z");
  const [link, setLink] = useState("");
  const [year] = useState(process.env.REACT_APP_CURRENT_YEAR);
  const [isEditVisible, setIsEditVisible] = useState(true);


  const [employers, setEmployers] = useState([]);
  const [employerName, setEmployerName] = useState("");
  const [tanNumber, setTanNumber] = useState("");

  const [queryValue, setQueryValue] = useState("");
  const [previousQueries, setPreviousQueries] = useState([]);

  useEffect(() => {
    const listen = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAadhaar(userData?.aadhaar);
        setIfscCode(userData?.IFSC);
        setEmployeeId(userData?.employeeId)
        setAccountNumber(userData?.accountNumber);
        //setPoliceStation(userData?.policeStation);
        setOfficeAddress(userData?.officeAddress);
        setEmail(userData?.email);
        setDesignation(userData?.Designation);
        setTAN(userData?.TAN);
        setMobile(userData?.mobile);
        setRoleSet(userData?.roleSet);
        if (authUser?.photoURL) {
          setPhotoUrl(authUser.photoURL);
        }
        const match = user.email.match(/\+(.*?)@/);
        const pan = match[1].toUpperCase();

        const currentYear = process.env.REACT_APP_CURRENT_YEAR;
        const questRef = doc(db, currentYear.toString(), pan);
        const questSnap = await getDoc(questRef);
        if (questSnap.exists()) {
          if (questSnap.data()["path"] === -1) {
            setLink("");
          } else {
            setLink(questSnap.data()["path"]);
          }
        }

        const employerRef = doc(db, currentYear.toString(), pan);
        const employerSnap = await getDoc(employerRef);
        setEmployers(employerSnap.data()?.Employers);

        const fetchPreviousQueries = async () => {
          if (authUser && authUser.email) {
            const match = authUser.email.match(/\+(.*?)@/);
            const pan = match[1].toUpperCase();
        
            const queriesCollection = collection(db, "queries");
            const q = query(queriesCollection, where("pan", "==", pan));
            const querySnapshot = await getDocs(q);
        
            const queries = querySnapshot.docs.map((doc) => ({
              id: doc.id, // Add document ID
              query: doc.data().query,
              status:doc.data().status
            }));
            console.log("Fetched queries:", queries);
            setPreviousQueries(queries);
          }
        };
        
        fetchPreviousQueries(); // Add this line
      }
    });
    return () => {
      listen();
    };
  }, [userData, authUser]);

  const handleAadharChange = (value) => {
    const inputValue = value;
    const formattedAadhar = formatAadharNumber(inputValue);
    setAadhaar(formattedAadhar);
  };

  const accountInfo = [
    {
      label: t("Bank Account Number"),
      value: accountNumber,
      name: "accountNumber",
      setValue: setAccountNumber,
    },
    {
      label: t("IFSC Code"),
      value: ifscCode,
      name: "ifscCode",
      setValue: setIfscCode,
    },
    {
      label: t("Aadhaar Number"),
      value: aadhaar,
      name: "aadhaar",
      setValue: handleAadharChange,
    },
    {
      label: t("Police Station"),
      value: policeStation,
      name: "policeStation",
      setValue: setPoliceStation,
    },
    {
      label: t("Employee ID"),
      value: employeeId,
      name: "employeeId",
      setValue: setEmployeeId,
    },
    {
      label: t("Designation"),
      value: Designation,
      name: "Designation",
      setValue: setDesignation,
    },
    {
      label: t("TAN"),
      value: TAN,
      name: "TAN",
      setValue: setTAN,
    },
    {
      label: t("PAN"),
      value: UserPan,
      name: "PAN",
      setValue: setUserPan,
    },
    {
      label: t("Office Address"),
      value: officeAddress,
      name: "officeAddress",
      setValue: setOfficeAddress,
    },
    {
      label: t("Email"),
      value: email,
      name: "email",
      setValue: setEmail,
    },
    {
      label: t("Mobile"),
      value: mobile,
      name: "mobile",
      setValue: setMobile,
    },
  ];

  const formatAadharNumber = (value) => {
    const numericValue = value.replace(/\D/g, "");
    let formattedValue = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedValue += " ";
      }
      formattedValue += numericValue[i];
    }
    return formattedValue;
  };

  // Uploading data into the database
  const updateEmployeer = useCallback((employers) => {
    const match = authUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    const tanSource = doc(db, year, pan);
    try {
      // console.log(employers);
      updateDoc(tanSource, { Employers: employers });
    } catch (err) {
      console.log(err);
    }
  });

  // updating the Employeer state
  const addEmployer = (e) => {
    e.preventDefault();
    if (employerName && tanNumber) {
      setEmployers([...employers, { employerName, tanNumber }]);
      updateEmployeer([...employers, { employerName, tanNumber }]);
      setEmployerName("");
      setTanNumber("");
    }
  };

  // Function to handle Aadhar number input change

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setEdit(false);
    const formData = new FormData(e.target);
    // console.log(accountInfo.accountNumber);
    // console.log(formData.get("accountNumber"));
    const obj = {
      accountNumber: formData.get("accountNumber"),
      IFSC: formData.get("ifscCode"),
      aadhaar: formData.get("aadhaar"),
      //policeStation: formData.get("policeStation"),
      employeeId: formData.get("employeeId"),
      Designation:formData.get("Designation"),
      TAN:formData.get("TAN"),
      officeAddress: formData.get("officeAddress"),
      email: formData.get("email"),
      mobile: formData.get("mobile"),
    };

    const match = authUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    const docRef = doc(db, "users", pan);
    try {
      updateDoc(docRef, obj);
      // console.log("Document updated successfully!");
      alert(t("Details Updated Successfully!"));
      // navigate to home page
      navigate("/dashboard", { replace: true });
    } catch (error) {
      console.error("Something went wrong, please try again");
      console.error("Error updating document: ", error);
    }
  }, []);

  const editAccountInfo = () => {
    setEdit(true);
  };

  const submitAccountInfo = () => {
    setEdit(false);
  };

  const onEmployerSubmit = (e) => {
    e.preventDefault();
    console.log("Employer Name: ", employerName);
    console.log("TAN Number: ", tanNumber);
  };

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        // console.log("sign out successful");
      })
      .catch((error) => console.log(error));
  };
  const whatsappMeFxn = () => {
    window.open("https://wa.me/7276016790");
  };
  const whatsappMeFxnOffice = () => {
    window.open("https://wa.me/7276016790");
  };

  const handleChange = (e) => {
    const maxAllowedSize = 0.5 * 1024 * 1024;
    if (e.target.files[0].size < maxAllowedSize) {
      setPhoto(e.target.files[0]);
      setLoading(false);
    } else {
      alert(t("Image size should be less than 500kb"));
      setLoading(true);
      return;
    }
  };

  const updateImg = async (e) => {
    const fileRef = ref(storage, "profileImg/" + authUser.uid + ".png");

    setLoading(true);
    // eslint-disable-next-line
    const snapshot = await uploadBytes(fileRef, photo);
    const photoURL = await getDownloadURL(fileRef);

    updateProfile(authUser, { photoURL });

    setLoading(false);
    alert(
      t("Profile Image Updated! It may take some time to display on profile...")
    );
    window.location.reload(false);
  };

  const handleClick = () => {
    if (link === "") {
      alert(t("No document provided!"));
    }
  };

  function addWeeks(date, weeks) {
    date.setDate(date.getDate() + 7 * weeks);

    return date;
  }

  const submitQuery = () => {
    if (!query) {
      alert("Query not entered");
    }

    raiseQuery(
      props.pan,
      props.name,
      mobile,
      new Date(),
      "not resolved",
      roleSet,
      queryValue,
      "",
      addWeeks(new Date(), 1)
    );
  };

  return (
    <div className="container-md mt-5">
      {/* profile image */}
      <center>
        <div className="box">
          {/* <!-- Button trigger modal --> */}
          <button
            type="button"
            className="btn btn-primary m-2"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            {t("Update Profile Image")}
          </button>

          {/* <!-- Modal --> */}
          <div
            className="modal fade"
            id="exampleModal"
            style={{ marginTop: "50px" }}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {t("Update Profile Image")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="text-center mt-2 ">
                    <img
                      src={photoUrl}
                      className="rounded-circle"
                      style={{ objectFit: "cover" }}
                      alt="user avatar"
                      width="150"
                      height="150"
                    // onChange={handleSelect}
                    ></img>
                    <input
                      className="row"
                      type="file"
                      accept="image/*"
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {t("Close")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={loading || !photo}
                    onClick={updateImg}
                  >
                    {t("Save changes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-signup btn-primary m-">
            <Link to="/auth/change_pwd">{t("Reset Password")}</Link>
          </button>
          <button
            className="btn btn-login btn-danger m-2"
            onClick={userSignOut}
          >
            {t("Log Out")}
          </button>
        </div>
      </center>
      {/* accordion starts */}
      <div className="accordion" id="accordionExample">
        {/* account details starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray position-relative">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill "
              // onClick={() => setIsEditVisible()}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {t("Account Details")}
            </button>
          </h2>
          {/* {isEditVisible ? (
            <div
              className="accordion-body  d-flex justify-content-end"
              style={{
                position: "absolute",
                top: "-5px",
                right: "50px",
                zIndex: "100",
              }}
            >
              {!edit ? (
                <EditButton onTap={editAccountInfo} />
              ) : (
                <SubmitButton />
              )}
            </div>
          ) : (
            <></>
          )} */}
          <div
            id="collapseOne"
            // className="accordion-collapse collapse show pb-3"
            data-bs-parent="#accordionExample"
          >
            <form onSubmit={onSubmit}>
              {accountInfo.map((item, index) => {
                const isPolice = roleSet == "Policeman";

                if (!isPolice && index == 3) {
                  return null;
                }

                return (
                  <div className="accordion-body py-2" key={index}>
                    <label className="d-flex text-light justify-content-between">
                      {item.label}
                    </label>
                    {index !== 8 ? (
                      <input
                        name={item.name}
                        type="text"
                        className="form-control rounded-pill border-0"
                        placeholder={t("Enter ") + item.label}
                        value={item.value}
                        readOnly={edit ? null : "readOnly"}
                        onChange={(e) => item.setValue(e.target.value)}
                      />
                    ) : (
                      <textarea
                        name={item.name}
                        type="text"
                        className="form-control rounded-pill border-0"
                        placeholder={t("Enter ") + item.label}
                        value={item.value}
                        style={{ resize: "none" }}
                        readOnly={edit ? null : "readOnly"}
                        onChange={(e) => item.setValue(e.target.value)}
                      ></textarea>
                    )}
                  </div>
                );
              })}
              <div className="accordion-body pb-4 d-flex justify-content-end pe-5">
                {!edit ? (
                  <EditButton onTap={editAccountInfo} />
                ) : (
                  <SubmitButton />
                )}
              </div>
            </form>
          </div>
        </div>
        {/* account details ends */}

        

        {/*Employer starts*/}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEmployer"
              aria-expanded="false"
              aria-controls="collapseEmployer"
            >
              {t("Employer Details")}
            </button>
          </h2>
          <div id="collapseEmployer" data-bs-parent="#accordionExample">
            <form onSubmit={onEmployerSubmit}>
              {/* <input value={userPAN || "Not defined"}></input> */}
              {employers?.length > 0 && (
                <div className="accordion-body py-2">
                  <div
                    style={{
                      background: "white",
                      borderRadius: "25px",
                      padding: "1rem",
                    }}
                  >
                    <h4>{t("Employers")}</h4>
                    <div className="employer-container">
                      {employers.map((employer, index) => (
                        <div key={index} className="employer-card">
                          {employer.employerName} - {employer.tanNumber}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="accordion-body py-2">
                <label className="d-flex text-light justify-content-between">
                  {t("Employer Name")}
                </label>
                <input
                  name="employerName"
                  type="text"
                  className="form-control rounded-pill border-0"
                  placeholder={t("Enter Employer Name")}
                  value={employerName}
                  onChange={(e) => setEmployerName(e.target.value)}
                />
              </div>
              <div className="accordion-body py-2">
                <label className="d-flex text-light justify-content-between">
                  {t("TAN Number")}
                </label>
                <input
                  name="tanNumber"
                  // type="number"
                  type="text"
                  pattern="[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}"
                  className="form-control rounded-pill border-0"
                  placeholder={t("Enter TAN Number")}
                  value={tanNumber}
                  onChange={(e) =>
                    setTanNumber(String(e.target.value).toUpperCase())
                  }
                />
                <button
                  className="btn btn-secondary rounded-pill 
                border-0 my-3"
                  onClick={addEmployer}
                >
                  {t("Add Employer")}
                </button>
              </div>
            </form>
          </div>
        </div>
        {/*Employer ends*/}

        {/* Previous ITR's starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              {t("Previous ITR's")}
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={handleClick}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value="2020-21"
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={handleClick}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value="2021-22"
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={handleClick}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value={year}
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
          </div>
        </div>
        {/* Previous ITR's ends */}

        {/* Form 16 section starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              {t("Form 16")}
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={handleClick}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value="2020-21"
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={handleClick}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value="2021-22"
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={handleClick}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value={year}
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
          </div>
        </div>
        {/* Form 16 ends here */}

        {/* Contact starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              {t("Contact")}
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse p-2"
            data-bs-parent="#accordionExample"
          >
            {/* contact detail 1 starts */}
            <div className="card contact-card m-4">
              <div className="row no-gutters px-4">
                <div className="col-md-8 d-flex justify-content-center p-3">
                  <div className="col-3 d-flex align-items-center">
                    <img
                      src="https://tinyurl.com/4ypa9c6z"
                      className="rounded-circle img-thumbnail img-fluid object-fit-cover"
                      alt="Profile avatar"
                      width="100px"
                      height="100px"
                    // onChange={handleSelect}
                    ></img>
                  </div>

                  <div className="col-9 d-flex justify-content-center align-items-center">
                    <div className="card-body">
                      <h5 className="card-title">
                        {t("Atharv Sawant (Founder & Chairman)")}
                      </h5>
                      <p className="card-text">+91 7276016790</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-center align-items-center">
                  <div className="m-2">
                    <a href="tel:7276016790">
                      <button type="button" className="btn btn-primary">
                        {t("Call")}
                      </button>
                    </a>
                  </div>
                  <div className="m-2">
                    <button
                      onClick={whatsappMeFxn}
                      type="button"
                      className="btn btn-secondary whatsapp-btn"
                    >
                      {t("Whatsapp")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* contact detail 1 ends */}

            {/* contact detail 2 starts */}
            <div className="card contact-card m-4">
              <div className="row no-gutters px-4">
                <div className="col-md-8 d-flex justify-content-center p-3">
                  <div className="col-3 d-flex align-items-center">
                    <img
                      src="assets/images/icons/favicon.png"
                      className="rounded-circle img-thumbnail img-fluid object-fit-cover"
                      alt="Profile avatar"
                      width="100px"
                      height="100px"
                    ></img>
                  </div>

                  <div className="col-9 d-flex justify-content-center align-items-center">
                    <div className="card-body">
                      <h5 className="card-title">{t("Tax Sarthi Office")}</h5>
                      <p className="card-text">+91 7276016790</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-center align-items-center">
                  <div className="m-2">
                    <a href="tel:7276016790">
                      <button type="button" className="btn btn-primary">
                        {t("Call")}
                      </button>
                    </a>
                  </div>
                  <div className="m-2">
                    <button
                      onClick={whatsappMeFxnOffice}
                      type="button"
                      className="btn btn-secondary whatsapp-btn"
                    >
                      {t("Whatsapp")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* contact detail 2 ends */}
          </div>
        </div>
        {/* Contact ends */}

        {/* Raise Query starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              {t("Raise Query")}
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse p-2"
            data-bs-parent="#accordionExample"
          >
            <label className="d-flex text-light justify-content-between">
              Enter Query
            </label>
            <input
              type="text-area"
              className="form-control rounded-pill border-0"
              value={queryValue}
              onChange={(e) => {
                setQueryValue(e.target.value);
              }}
              id="query input field"
            ></input>
            <button
              className="btn btn-secondary rounded-pill border-0 my-3"
              onClick={submitQuery}
            >
              Submit
            </button>
          </div>
        </div>
        {/* Raise Query ends */}

        {/* Previous Queries section */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              {t("Previous Queries")}
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse p-2"
            data-bs-parent="#accordionExample"
          >
            {/* Render previous queries here */}
            {previousQueries.map((query, index) => (
              <div key={index} className="query-item">
                <div style={{color: "white", marginLeft: "10px"}}>Query : {query.query} 
                  <span style={{color: "white", fontWeight:"800", fontSize:"20px", marginLeft: "300px"}}>Status : {query.status}</span>
                </div>
                
                {/* Add any other details you want to display */}
              </div>
            ))}
          </div>
        </div>

      </div>
      {/* accordion ends */}
    </div>
  );
};



export default function ProfileErrorBoundary(props) {
  return (
    <ErrorBoundary>
      <Profile
        name={props.name}
        pan={props.pan}
        phone={props.phone}
        userType={props.userType}
      />
    </ErrorBoundary>
  );
}
