import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase";
import { doc, setDoc, getDoc, collection } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
} from "firebase/auth";
import logo from "../../assets/images/logo.png";
import panCrop from "../../assets/images/panCrop.png";
import { responseSchema } from "../../data";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const SignUp = () => {
  const { t } = useTranslation();
  // useState for warning message
  const [warn, setWarn] = useState("");
  const newWarn = (value) => {
    setWarn(value);
    setTimeout(() => {
      setWarn("");
    }, 3000);
  };
  const navigate = useNavigate();

  // Redirect to home if user is logged in
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        return navigate("/", { replace: true });
      }
    });
    return () => {
      listen();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //All States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [pan, setPan] = useState("");
  const [mobile, setMobile] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [password_rep, setPassword_rep] = useState("");
  const [COUPON_CODE, setCoupon] = useState("");
  const [role, setRole] = useState(0);
  const [newPassword, setNewPassword] = useState("password");
  const [repeatPassword, setRepeatPassword] = useState("password");
  const [iconNew, setIconNew] = useState(eyeOff);
  const [iconRepeat, setIconRepeat] = useState(eyeOff);
  const [typeNew, setTypeNew] = useState("password");
  const [typeRepeat, setTypeRepeat] = useState("password");
  const [isLoading, setLoading] = useState(false);
  const [panExist, setPanExist] = useState(false);
  const array = [
    t("Policemen"),
    t("Defence Personal"),
    t("Private Corporate Employee"),
    t("Government Employee"),
    t("Self Employed or Freelancer or Student"),
  ];
  const question = responseSchema;

  const checkPanExist = async () => {
    try {
      const userRef = doc(db, "users", pan);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        setPanExist(true);
      } else {
        setPanExist(false);
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  useEffect(() => {
    if (pan.length === 10) {
      checkPanExist();
    }
  }, [pan]);

  const signUpUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (panExist) {
      setLoading(false);
      return;
    }

    const emailSplit = email.split("@");
    const emailPAN = emailSplit[0] + "+" + pan + "@" + emailSplit[1];

    if (password.length < 6) {
      setLoading(false);
      newWarn("Length of password must be greater than 6!");
      return;
    } else if (password !== password_rep) {
      setLoading(false);
      newWarn("Password and Confirm Password must be same!");
      return;
    } else {
      if (COUPON_CODE === "") {
        createUserWithEmailAndPassword(auth, emailPAN, password)
          .then((userCredential) => {
            // update user data in users collection
            const user = userCredential.user;
            const { uid } = user;
            const docRef = doc(db, "users", pan);
            const paymentRequired = true;
            const path = -1;
            const roleSet = array[role];
            // user data to be inserted
            const data = {
              name,
              email,
              pan,
              emailPAN,
              mobile,
              roleSet,
              paymentRequired,
              uid,
            };

            setDoc(docRef, data)
              .then(() => {
                // console.log("Document has been added successfully");
                // add question blank to yesr
                const currentYear = process.env.REACT_APP_CURRENT_YEAR;
                const field = [
                  { jan: 0, feb: 0, mar: 0 },
                  { jan: 0, feb: 0, mar: 0 },
                  { jan: 0, feb: 0, mar: 0 },
                  { jan: 0, feb: 0, mar: 0 },
                ];
                const docRef = doc(db, currentYear.toString(), pan);
                const data = {
                  uid,
                  pan,
                  emailPAN,
                  question,
                  path,
                  isVerified: false,
                  punch: false,
                  taxPaid: field,
                };
                setDoc(docRef, data);
                return navigate("/payment", { replace: true });
              })
              .catch((error) => {
                console.log(error);
              });
            return updateProfile(user, { displayName: name });
          })
          .then(() => {
            // console.log("Display name set successfully.");
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setLoading(false);
            newWarn(errorMessage);
            return;
          });
      } else {
        const docRef = doc(db, "coupon", COUPON_CODE.split("-")[0]);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && docSnap.data()["coupon"] === COUPON_CODE) {
          if (docSnap.data()["count"] === 0) {
            createUserWithEmailAndPassword(auth, emailPAN, password)
              .then((userCredential) => {
                // update user data in users collection
                const user = userCredential.user;
                const { uid, email } = user;
                const docRef = doc(db, "users", pan);
                const paymentRequired = false;
                const path = -1;
                const couponUsed = COUPON_CODE;
                const roleSet = array[role];
                // user data to be inserted
                const data = {
                  name,
                  email,
                  emailPAN,
                  pan,
                  mobile,
                  roleSet,
                  paymentRequired,
                  couponUsed,
                  uid,
                };

                setDoc(docRef, data)
                  .then(() => {
                    // console.log("Document has been added successfully");
                    // add question blank to yesr
                    const currentYear = process.env.REACT_APP_CURRENT_YEAR;
                    const field = [
                      { jan: 0, feb: 0, mar: 0 },
                      { jan: 0, feb: 0, mar: 0 },
                      { jan: 0, feb: 0, mar: 0 },
                      { jan: 0, feb: 0, mar: 0 },
                    ];
                    const docRef = doc(db, currentYear.toString(), pan);
                    const data = {
                      uid,
                      pan,
                      emailPAN,
                      question,
                      path,
                      isVerified: false,
                      punch: false,
                      taxPaid: field,
                    };
                    setDoc(docRef, data);
                    return navigate("/dashboard", { replace: true });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                return updateProfile(user, { displayName: name });
              })
              .then(() => {
                // console.log("Display name set successfully.");
                const uid = auth.currentUser.uid;
                const usedBy = uid;
                const count = 1;
                const coupon = COUPON_CODE;
                const data = {
                  count,
                  usedBy,
                  coupon,
                };
                const docset = doc(db, "coupon", COUPON_CODE.split("-")[0]);
                setDoc(docset, data);
                // console.log("Display name set successfully.");
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setLoading(false);
                newWarn(errorMessage);
                return;
              });
          } else {
            setLoading(false);
            newWarn("Coupon Already Used!");
            return;
          }
        } else {
          setLoading(false);
          newWarn("Coupon is Invalid!!");
          return;
        }
      }
    }
  };

  const handleToggleNew = () => {
    setTypeNew(typeNew === "password" ? "text" : "password");
    setIconNew(typeNew === "password" ? eye : eyeOff);
  };

  const handleToggleRepeat = () => {
    setTypeRepeat(typeRepeat === "password" ? "text" : "password");
    setIconRepeat(typeRepeat === "password" ? eye : eyeOff);
  };

  const handleInputFocus = () => {
    setShowPopUp(true);
  };

  const handleInputBlur = () => {
    setShowPopUp(false);
  };

  const [showPan, setshowPan] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  return (
    <div className="signup-page mt-5 p-4">
      <form onSubmit={signUpUser}>
        <center>
          <img src={logo} className="app-logo-sm" alt="logo" />
          <h1 className="mt-2">{t("Create Account")}</h1>
        </center>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            {t("Name")}
          </label>
          <input
            name="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="form-control"
            required
            onFocus={() => setshowName(true)}
            onBlur={() => setshowName(false)}
          />
          {showPopUp && <div className="popup">Enter your name here.</div>}
        </div>
        <div className="mb-3">
          <label
            htmlFor="pan"
            id="label-pan"
            className="form-label"
            style={{ paddingRight: "5px" }}
          >
            {t("PAN Number")}
            <label
              htmlFor="label-pan"
              className="panIcon"
              style={{
                textAlign: "center",
                border: "1px solid black",
                width: "1.3rem",
                borderRadius: "50%",
                height: "1.3rem",
                fontWeight: "600",
                justifyContent: "center",
                display: "flex",
                alignItems: "center ",
                marginLeft: "100px",
                marginTop: "-20px",
              }}
              onClick={() => setshowPan(true)}
              data-tooltip-id="my-tooltip"
              data-tooltip-delay-hide={3000}
            >
              ?
            </label>
          </label>
          <Tooltip
            id="my-tooltip"
            place="top-start"
            effect="solid"
            className="pan-tooltip"
          >
            <div>
              <p style={{fontWeight:"700", fontSize:"1.2rem"}}>PAN Format:</p>
              <img src={panCrop} alt="PAN format" style={{height:"50px"}} />
            </div>
          </Tooltip>

          <div className="input-group">
            <input
              name="pan"
              type="text"
              pattern="[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}"
              value={pan}
              onChange={(e) => setPan(e.target.value)}
              className="form-control"
              required
            />
          </div>
          {panExist && (
            <div
              className="popup"
              style={{ background: "#c0392b", color: "white" }}
            >
              Pan already exist
            </div>
          )}
          {/* {showPan && (
            <div className="popup" style={{ background: "lightblue" }}>
              {" "}
              PAN Format
              <img
                src={panCrop}
                style={{ height: "3rem", marginLeft: "13px" }}
              />
            </div>
          )} */}
        </div>
        <div className="mb-3">
          <label htmlFor="mobile" className="form-label">
            {t("Mobile")}
          </label>
          <input
            name="mobile"
            type="tel"
            pattern="[0-9]{10}"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            {t("Email")}
          </label>
          <input
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            required
          />
        </div>
        <div className="mb-3 position-relative">
          <label htmlFor="password" className="form-label">
            {t("Password")}
          </label>
          <input
            name="password"
            type={typeNew}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            id="exampleInputPassword1"
            required
          />
          <span
            style={{
              position: "absolute",
              display: "inline-block",
              verticalAlign: "middle",
              top: "37px",
              right: "9px",
              height: "22px",
            }}
            className="flex justify-around items-center"
            onClick={handleToggleNew}
          >
            <Icon className="absolute mr-10" icon={iconNew} size={25} />
          </span>
          {showPassword && (
            <div className="popup" style={{ background: "lightblue" }}>
              Length of Password should be greater than 6.
            </div>
          )}
        </div>

        <div className="mb-3 position-relative">
          <label htmlFor="repeatPassword" className="form-label">
            {t("Repeat Password")}
          </label>
          <input
            name="password_rep"
            type={typeRepeat}
            value={password_rep}
            onChange={(e) => setPassword_rep(e.target.value)}
            className="form-control"
            id="exampleInputPassword2"
            required
          />
          <span
            style={{
              position: "absolute",
              display: "inline-block",
              verticalAlign: "middle",
              top: "37px",
              right: "9px",
              height: "22px",
            }}
            className="flex justify-around items-center"
            onClick={handleToggleRepeat}
          >
            <Icon class="absolute mr-10" icon={iconRepeat} size={25} />
          </span>
          {showPopUp && (
            <div className="popup" style={{ background: "lightblue" }}>
              Repeated password should be same as the password entered above.
            </div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="coupon" className="form-label">
            {t("Coupon Code(if any)")}
          </label>
          <input
            name="coupon"
            type="text"
            value={COUPON_CODE}
            onChange={(e) => setCoupon(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="dropdown mb-3 ">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenu2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {t("I'm a")}, {array[role]}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => setRole(0)}
              >
                {array[0]}
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => setRole(1)}
              >
                {array[1]}
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => setRole(2)}
              >
                {array[2]}
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => setRole(3)}
              >
                {array[3]}
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => setRole(4)}
              >
                {array[4]}
              </button>
            </li>
          </ul>
        </div>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
            required
          />
          <label className="form-check-label" htmlFor="exampleCheck1">
            {t("I agree")}
          </label>
        </div>
        <button
          type="submit"
          className="btn btn-signup mt-3"
          disabled={isLoading}
        >
          {isLoading ? t("Loading...") : t("Register")}
        </button>
        {warn && (
          <div className="alert alert-warning mt-1" role="alert">
            {warn}
          </div>
        )}
        <div id="emailHelp" className="form-text mt-3">
          {t("Already_have_an_account")}{" "}
          <Link to="/auth/signin">{t("Login")}</Link>{" "}
        </div>
      </form>
    </div>
  );
};
export default SignUp;
