import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { auth, db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Library } from "../data";
import Question from "../components/question";

export const Questions = () => {
  const { quesId, year } = useParams();
  const [isLoading, setLoading] = useState(false);
  const quest = Library().questions;
  // warnign message
  const [warn, setWarn] = useState("");
  const newWarn = (value) => {
    setWarn(value);
    setTimeout(() => {
      setWarn("");
    }, 3000);
  };

  // index of the question
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  // get the income details
  const [gross, setGross] = useState([""]);
  const [recRent, setRecRent] = useState(["", ""]);
  const [tax, setTax] = useState([""]);
  const [interest, setInterest] = useState(["", "", "", "", ""]);

  // deduction details
  const [$80c, set$80c] = useState(["", "", "", ""]);
  const [nps, setNps] = useState([""]);
  const [$80d, set$80d] = useState(["", ""]);
  const [$80dd, set$80dd] = useState("");
  const [$80ddb, set$80ddb] = useState(["", ""]);
  const [recRentType, setRecRentType] = useState("");
  const [$80ddbRelation, set$80ddbRelation] = useState("");
  const [$80g, set$80g] = useState([""]);
  const [$80tta, set$80tta] = useState([""]);
  const [$80u, set$80u] = useState(["", ""]);
  const [$80gg, set$80gg] = useState([""]);
  const [$16b, set$16b] = useState([""]);
  const [home, sethome] = useState([""]);


  //allowances data
  const [costTourAllow, setcostTourAllow] = useState([""])
  const [childEduAllow, setchildEduAllow] = useState([""])
  const [rentAllow, setrentAllow] = useState([""])
  const [TravelAllow, setTravelAllow] = useState([""])
  const [otherAllow, setotherAllow] = useState([""])

  // const [data, setData] = useState({});

  // useEffect(() => {
  //   setData({
  //     gross: gross,
  //     recRent: recRent,
  //     interest: interest,
  //     tax: tax,
  //     $80c: $80c,
  //     nps: nps,
  //     $80d: $80d,
  //     $80dd: $80dd,
  //     $80ddb: $80ddb,
  //     $80g: $80g,
  //     $80gg: $80gg,
  //   });
  // }, [
  //   $80c,
  //   $80d,
  //   $80dd,
  //   $80ddb,
  //   $80g,
  //   $80gg,
  //   gross,
  //   interest,
  //   nps,
  //   recRent,
  //   tax,
  // ]);
  const covertToNumber = (arr = []) => {
    let newArr = [];
    arr.forEach((item, index) => {
      newArr[index] = item.toString().split(",").join("");
    });
    return newArr;
  };
  // submit the data in one array
  const submitForm = async (
    field1,
    field2,
    index1 = +quesId,
    field3 = "",
    field4 = [],
    index2 = null
  ) => {
    setLoading(true);
    // if (data !== {}) {
    const user = auth.currentUser;
    const currentYear = year ? year : process.env.REACT_APP_CURRENT_YEAR;
    const match = user.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    const docRef = doc(db, currentYear.toString(), pan);
    try {
      if (!field3) {
        await updateDoc(
          docRef,
          {
            [`question.${field1}`]: covertToNumber(field2),
            [`question.suggestions.${index1}`]: false,
          },
          { merge: true }
        );
      } else {
        await updateDoc(
          docRef,
          {
            [`question.${field1}`]: covertToNumber(field2),
            [`question.${field3}`]: covertToNumber(field4),
            [`question.suggestions.${index1}`]: false,
            [`question.suggestions.${index2}`]: false,
          },
          { merge: true }
        );
      }
      setLoading(false);
      navigate("/dashboard", { replace: true });
    } catch (error) {
      setLoading(false);
      newWarn("Something went wrong, please try again");
      console.error("Error updating document: ", error);
    }

    setLoading(false);
    // }
  };

  return (
    <>
      {/* // warning message */}
      {warn && (
        <div className="alert alert-danger quest-alert" style={{ margin: 0 }}>
          {warn}
        </div>
      )}
      {
        //question 0
        (quesId === "nbsp" && index === 0 && (
          <Question
            showUpload={true}
            isNBSP={true}
            questID={0}
            question={quest[0]}
            state={[gross, setGross]}
            label={["Enter Gross Annual Income"]}
            suggestion={false}
            onAnswer={() => {
              if (gross[0] !== "" && Number(gross[0].split(",").join("")) > 0) {
                setIndex(index + 1);
              } else {
                newWarn("Please enter your correct gross income");
              }
            }}
          />
        )) ||
        (quesId === "nbsp" && index === 1 && (
          <Question
            showUpload={true}
            isNBSP={true}
            questID={4}
            question={quest[4]}
            state={[$80c, set$80c]}
            label={[
              "Premium for LIC",
              "Premium for Home Loan",
              "Premium for GPF",
              "Annual Tution Fees",
            ]}
            onSubmit={() => {
              // cheack if vlaues are positive
              if (
                (Number($80c[0].split(",").join("")) >= 0 ||
                  $80c[0] === "") &&
                (Number($80c[1].split(",").join("")) >= 0 ||
                  $80c[1] === "") &&
                (Number($80c[2].split(",").join("")) >= 0 ||
                  $80c[2] === "") &&
                (Number($80c[3].split(",").join("")) >= 0 || $80c[3] === "")
              ) {
                submitForm("gross", gross, 0, "$80c", $80c, 4);
              } else {
                newWarn("Value can't be negative.");
              }
            }}
            onPrev={() => {
              setIndex(index - 1);
            }}
          />
        ))
      }
      {+quesId === 0 && (
        <Question
          showUpload={true}
          questID={quesId}
          question={quest[0]}
          state={[gross, setGross]}
          label={["Enter Gross Annual Income"]}
          suggestion={false}
          onSubmit={() => {
            if (gross[0] !== "" && Number(gross[0].split(",").join("")) > 0) {
              submitForm("gross", gross);
            } else {
              newWarn("Please enter your correct gross income");
            }
          }}
        />
      )}
      {
        // question 2
        +quesId === 1 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[recRent, setRecRent]}
            dropdown={{
              label: "Type",
              options: ["Self Occupied", "Let"],
              value: recRentType,
              onChange: (value) => setRecRentType(value),
            }}
            label={["Enter MONTHLY Income from Rent", "Enter Annually Property tax Income"]}


            onSubmit={() => {
              // Get the numeric value of the entered rent
              const rentValue = Number(recRent[0].split(",").join(""));

              // Get the numeric value of the entered property tax
              const propertyTaxValue = Number(recRent[1].split(",").join(""));

              // Determine whether the rent should be positive or negative based on the dropdown value
              const adjustedRent = recRentType === "Self Occupied" ? -Math.abs(rentValue) : Math.abs(rentValue);

              // Calculate the difference between property tax and adjusted rent
              const amountA = (adjustedRent * 12) - propertyTaxValue;

              // Calculate 30% of amountA
              const amountB = amountA * 0.3;

              // Calculate the final amount (amountC)
              const amountC = amountA - amountB;

              // Store the final amount (amountC) in the form
              submitForm("recRent", [amountC.toString()]);
            }}
          />
        )

      }
      {
        // question 3
        +quesId === 2 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[tax, setTax]}
            label={["Enter Municipal tax paid"]}
            onSubmit={() => {
              if (Number(tax[0].split(",").join("")) >= 0 || tax[0] === "") {
                submitForm("tax", tax);
              } else newWarn("Value cant be negative.");
            }}
          />
        )
      }
      {
        // question 4
        +quesId === 3 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[interest, setInterest]}
            label={[
              "Bank Interest",
              "Interest from RD",
              "Interest from FD",
              "Interest from Dividend",
              "Interest from other Investments",

            ]}
            onSubmit={() => {
              if (
                Number(interest[0].split(",").join("")) >= 0 ||
                interest[0] === ""
              ) {
                submitForm("interest", interest);
              } else {
                newWarn("Value cant be negative.");
              }
            }}
          />
        )
      }
      {
        // question 1
        +quesId === 4 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[$80c, set$80c]}
            label={[
              "Premium for LIC",
              "Premium for Home Loan",
              "Premium for GPF",
              "Annual Tution Fees",
            ]}
            onSubmit={() => {
              // cheack if vlaues are positive
              if (
                (Number($80c[0].split(",").join("")) >= 0 || $80c[0] === "") &&
                (Number($80c[1].split(",").join("")) >= 0 || $80c[1] === "") &&
                (Number($80c[2].split(",").join("")) >= 0 || $80c[2] === "") &&
                (Number($80c[3].split(",").join("")) >= 0 || $80c[3] === "")
              ) {
                submitForm("$80c", $80c);
              } else {
                newWarn("Value cant be negative.");
              }
            }}
          />
        )
      }
      {
        // question 5
        +quesId === 5 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[nps, setNps]}
            label={["Enter Amount paid for NPS"]}
            onSubmit={() => {
              if (Number(nps[0].split(",").join("")) >= 0 || nps[0] === "") {
                submitForm("nps", nps);
              } else {
                newWarn("Value cant be negative.");
              }
            }}
          />
        )
      }
      {
        // question 6
        +quesId === 6 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[$80d, set$80d]}
            label={["for below 60 years", "for above 60 years"]}
            onSubmit={() => {
              // cheack if vlaues are positive
              if (
                (Number($80d[0].split(",").join("")) >= 0 || $80d[0] === "") &&
                (Number($80d[1].split(",").join("")) >= 0 || $80d[1] === "")
              ) {
                submitForm("$80d", $80d);
              } else {
                newWarn("Value cant be negative.");
              }
            }}
          />
        )
      }
      {+quesId === 7 && (
        <Question
          questID={quesId}
          question={quest[+quesId]}
          radio={true}
          state={[$80dd, set$80dd]}
          options={["No disability", "Less than 80%", "More than 80%"]}
          onSubmit={() => {
            if ($80dd[0] >= 0 || $80dd[0] === "") {
              submitForm("$80dd", $80dd);
            } else {
              newWarn("Value cant be negative.");
            }
          }}
        />
      )}
      {+quesId === 8 && (
        <Question
          showUpload={true}
          questID={quesId}
          question={quest[+quesId]}
          state={[$80ddb, set$80ddb, $80ddbRelation, set$80ddbRelation]}
          dropdown={{
            label: "Relation",
            options: ["Parent", "Self"],
            value: $80ddbRelation,
            onChange: (value) => set$80ddbRelation(value),
          }}
          label={["for below 60 years", "for above 60 years"]}
          onSubmit={() => {
            // Check if values are positive
            if (
              (Number($80ddb[0].split(",").join("")) >= 0 || $80ddb[0] === "") &&
              (Number($80ddb[1].split(",").join("")) >= 0 || $80ddb[1] === "") &&
              ($80ddbRelation === "Parent" || $80ddbRelation === "Child")
            ) {
              submitForm("$80ddb", $80ddb, "$80ddbRelation", [$80ddbRelation]);
            } else {
              newWarn("Value can't be negative or relation is missing.");
            }
          }}
          onPrev={() => {
            setIndex(index - 1);
          }}
        />
      )}
      {+quesId === 9 && (
        <Question
          showUpload={true}
          questID={quesId}
          question={quest[+quesId]}
          state={[$80g, set$80g]}
          label={["Enter the amount Donated"]}
          onSubmit={() => {
            if (Number($80g[0].split(",").join("")) >= 0 || $80g[0] === "") {
              submitForm("$80g", $80g);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 10 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Enter the rent paid ANNUALLY"]}
          state={[$80gg, set$80gg]}
          onSubmit={() => {
            if (Number($80gg[0].split(",").join("")) >= 0 || $80gg[0] === "") {
              submitForm("$80gg", $80gg);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}

      {+quesId === 11 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Income from other employer(s)"]}
          state={[$16b, set$16b]}
          onSubmit={() => {
            if (Number($16b[0].split(",").join("")) >= 0 || $16b[0] === "") {
              submitForm("$16b", $16b);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 12 && (
        <Question
          //showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Cost of travel on tour or transfer, maintainence of uniform"]}
          state={[costTourAllow, setcostTourAllow]}
          onSubmit={() => {
            if (Number(costTourAllow[0].split(",").join("")) >= 0 || costTourAllow[0] === "") {
              submitForm("costTourAllow", costTourAllow);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 13 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Children Education allowance, Hostel Allowance"]}
          state={[childEduAllow, setchildEduAllow]}
          onSubmit={() => {
            if (Number(childEduAllow[0].split(",").join("")) >= 0 || childEduAllow[0] === "") {
              submitForm("childEduAllow", childEduAllow);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 14 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["House Rent Allowance"]}
          state={[rentAllow, setrentAllow]}
          onSubmit={() => {
            if (Number(rentAllow[0].split(",").join("")) >= 0 || rentAllow[0] === "") {
              submitForm("rentAllow", rentAllow);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 18 && (
        <Question
          showUpload={true}
          questID={quesId}
          question={quest[+quesId]}
          state={[$80u, set$80u]}
          label={["Self with Disability", "Self with severe disability"]}
          onSubmit={() => {
            // cheack if vlaues are positive
            if (
              (Number($80u[0].split(",").join("")) >= 0 ||
                $80u[0] === "") &&
              (Number($80u[1].split(",").join("")) >= 0 || $80u[1] === "")
            ) {
              submitForm("$80u", $80u);
            } else {
              newWarn("Value cant be negative.");
            }
          }}
        />
      )}
      {+quesId === 19 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Enter your interest amount"]}
          state={[$80tta, set$80tta]}
          onSubmit={() => {
            if (Number($80tta[0].split(",").join("")) >= 0 || $80tta[0] === "") {
              submitForm("$80tta", $80tta);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 15 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Travel Allowance"]}
          state={[TravelAllow, setTravelAllow]}
          onSubmit={() => {
            if (Number(TravelAllow[0].split(",").join("")) >= 0 || TravelAllow[0] === "") {
              submitForm("TravelAllow", TravelAllow);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}


      {+quesId === 16 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Other Allowances"]}
          state={[otherAllow, setotherAllow]}
          onSubmit={() => {
            if (Number(otherAllow[0].split(",").join("")) >= 0 || otherAllow[0] === "") {
              submitForm("otherAllow", otherAllow);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 17 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Enter the interest on home loan"]}
          state={[home, sethome]}
          onSubmit={() => {
            if (Number(home[0].split(",").join("")) >= 0 || home[0] === "") {
              submitForm("home", home);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
    </>
  );
};
