import React, { useState, useEffect } from "react";
import "./course.css";

function Learning() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const playlistUrl = "https://www.youtube.com/playlist?list=PLtztnlFXOSfydSp27GrEjxVTEMTr8eP-j";
    const apiKey = "AIzaSyBTtWqwbO1ImsUzzJyVI0Ot1_a5ngA7ONQ";

    const regex = /list=([^&]*)/;
    const match = regex.exec(playlistUrl);
    const videoIds = match ? match[1] : null;

    if (videoIds) {
      const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${videoIds}&key=${apiKey}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          const videoList = data.items.map((item) => ({
            id: item.snippet.resourceId.videoId,
            title: item.snippet.title,
            description: item.snippet.description,
            thumbnail: item.snippet.thumbnails.default.url,
          }));
          setVideos(videoList);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  return (
    <div className="learning-container">
      <h1 className="learning-heading">Course Playlist</h1>
      <div className="video-list">
        {videos.map((video) => (
          <div
            className="video-item"
            key={video.id}
            onClick={() => window.open(`https://www.youtube.com/watch?v=${video.id}`, "_blank")}
          >
            <img src={video.thumbnail} alt={video.title} />
            <h3>{video.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Learning;