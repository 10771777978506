import React, { useState, useEffect } from "react";
import { getAuth, updatePassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import logo from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";

const ChangePwd = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = getAuth();
  const [warn, setWarn] = useState("");

  const newWarn = (value) => {
    setWarn(value);
    setTimeout(() => {
      setWarn("");
    }, 3000);
  };

  // Redirect to home if user is logged in
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/auth/change_pwd", { replace: true });
        // console.log("user logged in");
      } else {
        navigate("/", { replace: true });
      }
    });
    return () => {
      listen();
    };
  }, []);

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [iconNew, setIconNew] = useState(eyeOff);
  const [iconConfirm, setIconConfirm] = useState(eyeOff);
  const [typeNew, setTypeNew] = useState("password");
  const [typeConfirm, setTypeConfirm] = useState("password");

  const reset = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      newWarn("New Password and Confirm Password must be same!");
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    updatePassword(user, newPassword)
      .then(() => {
        newWarn("Password updated successfully.");
      })
      .catch((error) => {
        // newWarn(error);
        const errorMessage = error.message;
        console.log(error.code);
        newWarn(errorMessage);
      });
  };

  const handleToggleNew = () => {
    setTypeNew(typeNew === "password" ? "text" : "password");
    setIconNew(typeNew === "password" ? eye : eyeOff);
  };

  const handleToggleConfirm = () => {
    setTypeConfirm(typeConfirm === "password" ? "text" : "password");
    setIconConfirm(typeConfirm === "password" ? eye : eyeOff);
  };
  return (
    <div className="signin-page mt-5 p-4">
      <form onSubmit={reset}>
        <center>
          <img src={logo} className="app-logo-sm" alt="logo" />
          <h1 className="mt-2">{t("Reset Password")}</h1>
        </center>
        {warn && (
          <div className="alert alert-info mt-1" role="alert">
            {warn}
          </div>
        )}

        <div className="mb-3 position-relative">
          <label htmlFor="password" className="form-label">
            {t("New Password")}
          </label>
          <input
            name="password"
            type={typeNew}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="form-control"
            id="exampleInputPassword1"
            required
          />
          <span
            style={{
              position: "absolute",
              display: "inline-block",
              verticalAlign: "middle",
              top: "37px",
              right: "9px",
              height: "22px",
            }}
            className="flex justify-around items-center"
            onClick={handleToggleNew}
          >
            <Icon className="absolute mr-10" icon={iconNew} size={25} />
          </span>
        </div>

        <div className="mb-3 position-relative">
          <label htmlFor="newPassword" className="form-label">
            {t("Confirm Password")}
          </label>
          <input
            name="newPassword"
            type={typeConfirm}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-control"
            id="exampleInputPassword1"
            required
          />
          <span
            style={{
              position: "absolute",
              display: "inline-block",
              verticalAlign: "middle",
              top: "37px",
              right: "9px",
              height: "22px",
            }}
            className="flex justify-around items-center"
            onClick={handleToggleConfirm}
          >
            <Icon class="absolute mr-10" icon={iconConfirm} size={25} />
          </span>
        </div>

        <button type="submit" className="btn btn-signup mt-3">
          {t("Submit")}
        </button>
      </form>
    </div>
  );
};

export default ChangePwd;
