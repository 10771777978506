// import UserCard from "../components/user-card";
import { React, useEffect, useRef, useState } from "react";
import {
  analyseRes,
  TotalTaxableIncome,
  TotalTaxPaid,
  modifyPdf,
  useUserData,
  useUser,
} from "../data";
import PartApdf from "../data/Pdf";
import logo from "../assets/images/logo.png";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useTranslation } from "react-i18next";
import Status from "./Status";

import { ListItem, YearSelector, CardField, ShowAllButton } from "../components";

// ShowAllButton,
const Dashboard = (userPAN = { userPAN }) => {

  const { t } = useTranslation();
  const prevYearRef = useRef();

  const [loading] = useState(false);
  const [year, setYear] = useState(process.env.REACT_APP_CURRENT_YEAR);
  const [userData, setUserData] = useState(null);
  const [Data, setData] = useState(null);
  const [notice, setNotice] = useState("No notice from admin");
  const [res, setRes] = useState(analyseRes(userData?.question));
  const [otherEmp, setotherEmp] = useState(0);

  const userHookData = useUserData(year);
  console.log("data from current year :", userHookData);

  const usersData = useUser();
  console.log("user data is :", usersData);

  //------------------------------------FORM16 PARTA DATA -------------------------------------------------

  const aprTax = userHookData?.taxPaid?.[0]?.april?.taxDeposited ?? 0;
  const mayTax = userHookData?.taxPaid?.[0]?.may?.taxDeposited ?? 0;
  const junTax = userHookData?.taxPaid?.[0]?.june?.taxDeposited ?? 0;
  const julTax = userHookData?.taxPaid?.[1]?.july?.taxDeposited ?? 0;
  const augTax = userHookData?.taxPaid?.[1]?.aug?.taxDeposited ?? 0;
  const sepTax = userHookData?.taxPaid?.[1]?.sept?.taxDeposited ?? 0;
  const octTax = userHookData?.taxPaid?.[2]?.oct?.taxDeposited ?? 0;
  const novTax = userHookData?.taxPaid?.[2]?.nov?.taxDeposited ?? 0;
  const decTax = userHookData?.taxPaid?.[2]?.dec?.taxDeposited ?? 0;
  const janTax = userHookData?.taxPaid?.[3]?.jan?.taxDeposited ?? 0;
  const febTax = userHookData?.taxPaid?.[3]?.feb?.taxDeposited ?? 0;
  const marTax = userHookData?.taxPaid?.[3]?.mar?.taxDeposited ?? 0;

  const taxTotal = aprTax + mayTax + junTax + julTax + augTax + sepTax + octTax + novTax + decTax + janTax + febTax + marTax ;
  
  const sumQ1 = aprTax + mayTax + junTax;
  const sumQ2 =  julTax + augTax + sepTax;
  const sumQ3 = octTax + novTax + decTax;
  const sumQ4 = janTax + febTax + marTax;

  const taxDeductedSum = sumQ1 + sumQ2 + sumQ3 + sumQ4;

  const ReceiptNoQ1 = userHookData?.taxPaid?.[0]?.recNo ?? 0;
  const ReceiptNoQ2 = userHookData?.taxPaid?.[1]?.recNo ?? 0;
  const ReceiptNoQ3 = userHookData?.taxPaid?.[2]?.recNo ?? 0;
  const ReceiptNoQ4 = userHookData?.taxPaid?.[3]?.recNo ?? 0;

  const recNoApr = userHookData?.taxPaid?.[0]?.april?.recNo24G ?? 0;
  const recNoMay = userHookData?.taxPaid?.[0]?.may?.recNo24G ?? 0;
  const recNoJun = userHookData?.taxPaid?.[0]?.june?.recNo24G ?? 0;
  const recNoJul = userHookData?.taxPaid?.[1]?.july?.recNo24G ?? 0;
  const recNoAug = userHookData?.taxPaid?.[1]?.aug?.recNo24G ?? 0;
  const recNoSep = userHookData?.taxPaid?.[1]?.sept?.recNo24G ?? 0;
  const recNoOct = userHookData?.taxPaid?.[2]?.oct?.recNo24G ?? 0;
  const recNoNov = userHookData?.taxPaid?.[2]?.nov?.recNo24G ?? 0;
  const recNoDec = userHookData?.taxPaid?.[2]?.dec?.recNo24G ?? 0;
  const recNoJan = userHookData?.taxPaid?.[3]?.jan?.recNo24G ?? 0;
  const recNoFeb = userHookData?.taxPaid?.[3]?.feb?.recNo24G ?? 0;
  const recNoMar = userHookData?.taxPaid?.[3]?.mar?.recNo24G ?? 0;

  const DDOApr = userHookData?.taxPaid?.[0]?.april?.DDO ?? 0;
  const DDOMay = userHookData?.taxPaid?.[0]?.may?.DDO ?? 0;
  const DDOJun = userHookData?.taxPaid?.[0]?.june?.DDO ?? 0;
  const DDOJul = userHookData?.taxPaid?.[1]?.july?.DDO ?? 0;
  const DDOAug = userHookData?.taxPaid?.[1]?.aug?.DDO ?? 0;
  const DDOSep = userHookData?.taxPaid?.[1]?.sept?.DDO ?? 0;
  const DDOOct = userHookData?.taxPaid?.[2]?.oct?.DDO ?? 0;
  const DDONov = userHookData?.taxPaid?.[2]?.nov?.DDO ?? 0;
  const DDODec = userHookData?.taxPaid?.[2]?.dec?.DDO ?? 0;
  const DDOJan = userHookData?.taxPaid?.[3]?.jan?.DDO ?? 0;
  const DDOFeb = userHookData?.taxPaid?.[3]?.feb?.DDO ?? 0;
  const DDOMar = userHookData?.taxPaid?.[3]?.mar?.DDO ?? 0;

  const salaryQ1 = userHookData?.taxPaid?.[0]?.salary ?? 0;
  const salaryQ2 = userHookData?.taxPaid?.[1]?.salary ?? 0;
  const salaryQ3 = userHookData?.taxPaid?.[2]?.salary ?? 0;
  const salaryQ4 = userHookData?.taxPaid?.[3]?.salary ?? 0;

  const salaryTotal = salaryQ1 + salaryQ2 + salaryQ3 + salaryQ4;

  const dateApr = userHookData?.taxPaid?.[0]?.april?.date ?? 0;
  const dateMay = userHookData?.taxPaid?.[0]?.may?.date ?? 0;
  const dateJun = userHookData?.taxPaid?.[0]?.june?.date ?? 0;
  const dateJul = userHookData?.taxPaid?.[1]?.july?.date ?? 0;
  const dateAug = userHookData?.taxPaid?.[1]?.aug?.date ?? 0;
  const dateSep = userHookData?.taxPaid?.[1]?.sept?.date ?? 0;
  const dateOct = userHookData?.taxPaid?.[2]?.oct?.date ?? 0;
  const dateNov = userHookData?.taxPaid?.[2]?.nov?.date ?? 0;
  const dateDec = userHookData?.taxPaid?.[2]?.dec?.date ?? 0;
  const dateJan = userHookData?.taxPaid?.[3]?.jan?.date ?? 0;
  const dateFeb = userHookData?.taxPaid?.[3]?.feb?.date ?? 0;
  const dateMar = userHookData?.taxPaid?.[3]?.mar?.date ?? 0;





  //----------------------------------FORM16 PARTA----------------------------------------------------

  


  const [Name, setName] = useState("");
  const [empId, setempId] = useState("");
  const [pan, setpan] = useState("");
  const [officeAddress, setofficeAddress] = useState(usersData?.officeAddress);

  const [tax, setTax] = useState(TotalTaxPaid(userData?.taxPaid));
  const [showDeduction, setShowDeduction] = useState(false);

  const userHookRes = analyseRes(userHookData?.question);

  const userHookTax = TotalTaxPaid(userHookData?.taxPaid);
  const getNotice = async () => {
    const docRef = doc(db, "notices", "notice");
    const docSnap = await getDoc(docRef);
    setNotice(docSnap.data()?.notice);
  };


  useEffect(() => {
    setUserData(userHookData);
    setData(Data);
    setTax(userHookTax);
    setRes(analyseRes(userHookData?.question));
    getNotice();
  }, []);
  useEffect(() => {
    setRes(userHookRes);
    setTax(userHookTax);
    console.log("res is set:", res);
  }, [userHookData, usersData]);
  useEffect(() => {
    if (prevYearRef.current !== year) {
      // console.log("Year Changed");
      prevYearRef.current = year;
      setUserData(userHookData);
      setRes(userHookRes);

      setTax(userHookTax);
    }
  }, [year, userHookData, res, userHookRes]);

  let needForDeduction = true;
  let dispalyDeduction = true;
  if (TotalTaxableIncome(res.incomeData, res.deductionData) <= 0) {
    needForDeduction = false;
  }

  // console.log(data);

  const getUserDetails = async () => {
    const match = auth.currentUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();

    const docRef = doc(db, "users", pan);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const name = data.name;

    return name;
  };


  const address = usersData?.officeAddress;
  const addressMaxWords = 8;
  let addressLine1 = "";
  let addressLine2 = "";

  if (address) {
    const words = address.split(" ");
    addressLine1 = words.slice(0, addressMaxWords).join(" ");
    addressLine2 = words.slice(addressMaxWords).join(" ");
  }

  const QuarterObj = {
    sumQ1 : sumQ1,
    sumQ2 : sumQ2,
    sumQ3 : sumQ3,
    sumQ4 : sumQ4,
    taxDeductedSum : taxDeductedSum,
    ReceiptNoQ1 : ReceiptNoQ1,
    ReceiptNoQ2 : ReceiptNoQ2,
    ReceiptNoQ3 : ReceiptNoQ3,
    ReceiptNoQ4 : ReceiptNoQ4,
    salaryQ1 : salaryQ1,
    salaryQ2 : salaryQ2,
    salaryQ3 : salaryQ3,
    salaryQ4 : salaryQ4,
    salaryTotal : salaryTotal,
    aprTax : aprTax,
    mayTax : mayTax,
    junTax : junTax,
    julTax : julTax,
    augTax : augTax,
    sepTax : sepTax,
    octTax : octTax,
    novTax : novTax,
    decTax : decTax,
    janTax : janTax,
    febTax : febTax,
    marTax : marTax,
    taxTotal : taxTotal,
    recNoApr : recNoApr,
    recNoMay : recNoMay,
    recNoJun : recNoJun,
    recNoJul : recNoJul,
    recNoAug : recNoAug,
    recNoSep : recNoSep,
    recNoOct : recNoOct,
    recNoNov : recNoNov,
    recNoDec : recNoDec,
    recNoJan : recNoJan,
    recNoFeb : recNoFeb,
    recNoMar : recNoMar,
    DDOApr : DDOApr,
    DDOMay : DDOMay,
    DDOJun : DDOJun,
    DDOJul : DDOJul,
    DDOAug : DDOAug,
    DDOSep : DDOSep,
    DDOOct : DDOOct,
    DDONov : DDONov,
    DDODec : DDODec,
    DDOJan : DDOJan,
    DDOFeb : DDOFeb,
    DDOMar : DDOMar,
    dateApr : dateApr,
    dateMay : dateMay,
    dateJun : dateJun,
    dateJul : dateJul,
    dateAug : dateAug,
    dateSep : dateSep,
    dateOct : dateOct,
    dateNov : dateNov,
    dateDec : dateDec,
    dateJan : dateJan,
    dateFeb : dateFeb,
    dateMar : dateMar,
    
  }

  console.log("quarterObj is : ", QuarterObj);



  const userObject = {
    year: year,
    name: usersData?.name,
    empId: usersData?.employeeId,
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    pan: userHookData?.pan,
    tan: usersData.TAN,
    uid: userHookData?.uid,
    Designation : usersData?.Designation,

  };

  const calculateDeductionTotal = (section) => {
    let sum = 0;
    for (
      let index = 0;
      index < userHookData?.question?.[section].length;
      index++
    ) {
      const current = parseInt(userHookData?.question?.[section][index]);
      sum = sum + current;
    }
    return sum;
  };

  let salary = res.incomeData[0].amt;
  let gSalaryB = 0;
  let gSalaryC = 0;
  let gSalaryD = 0;
  let WATA = res.allowancesData[3].amt;
  //console.log("travel allowance is :", WATA);
  let HRA = res.allowancesData[2].amt;
  //console.log("house rent allowance :", HRA);
  let ExcPayRec = res.allowancesData[0].amt + res.allowancesData[1].amt + res.allowancesData[4].amt;
  //console.log("other allowances are :", ExcPayRec);
  let totalgross = salary + gSalaryB + gSalaryC + gSalaryD;
  let AllowanceTotal = WATA + HRA + ExcPayRec;
  //console.log("allowances total:", AllowanceTotal);
  let Balance3 = totalgross - AllowanceTotal;
  let standardDeduction = 50000;
  let entertainmentAllowance = 0;
  let taxOnEmployment = 2500;
  let Aggregate5 = standardDeduction + entertainmentAllowance + taxOnEmployment;
  let incomeChargableSalaries = Balance3 - Aggregate5;
  const homeLoanIndex = res.deductionData.findIndex(item => item.name === "Interest on Home Loan");



  const interestonhomeloan = res.deductionData[homeLoanIndex].amt;


  //console.log("Interest on Home Loan:", interestonhomeloan);

  let houseProperty = res.incomeData[1].amt - interestonhomeloan;
  //console.log("houseProperty", houseProperty);
  let otherIncome = res.incomeData[2].amt;
  let total7 = houseProperty + otherIncome;
  let grossTotalIncome = incomeChargableSalaries + total7;
  let $80c = calculateDeductionTotal("$80c");
  let $80Total =
    calculateDeductionTotal("$80c") < 150000
      ? calculateDeductionTotal("$80c")
      : 150000;
  let $80gg = calculateDeductionTotal("$80c") < 60000
    ? calculateDeductionTotal("$80c")
    : 60000;
  //console.log("$80gg : ", $80gg);
  let $80d = res.deductionData[6].amt ? res.deductionData[6].amt : 0;
  console.log("$80d : ", $80d);
  let $80dd = res.deductionData[7].amt ? res.deductionData[7].amt : 0;

  let $80ddb = res.deductionData[8].amt ? res.deductionData[8].amt : 0;
  console.log("$80ddb : ", $80ddb);
  let $80CCD1B = res.deductionData[5].amt;
  //console.log("$80CCD1B : ", $80CCD1B);
  //console.log("userType is :", usersData?.userType);
  let $80CCD2;

  if (usersData?.userType === "Police" || usersData?.userType === "Defence Personal" || usersData?.userType === "Government Employee") {
    $80CCD2 = parseInt(grossTotalIncome * 0.14);
  } else if (usersData?.userType === "Self Employed" || usersData?.userType === "Freelancer" || usersData?.userType === "Student" || usersData?.userType === "Private Corporate Employee") {
    $80CCD2 = parseInt(grossTotalIncome * 0.10);
  } else {
    // Handle other cases or set a default value for $80CCD2
    $80CCD2 = 0; // Default value
  }

  console.log("$80CCD2: ", $80CCD2);

  //console.log("80ccd2 : ", $80CCD2);
  let $80U = res.deductionData[11].amt ? res.deductionData[11].amt : 0;
  //console.log("$80U : ", $80U);
  let $80E = 0;
  let $80G = res.deductionData[9].amt ? res.deductionData[9].amt : 0;
  //console.log("$80G : ", $80G);
  let $80EEB;
  if (res.incomeData && res.incomeData.length > 3 && res.incomeData[3].amt !== undefined) {
    const amount = Number(res.incomeData[3].amt);
    $80EEB = amount > 10000 ? 10000 : amount;
  } else {
    $80EEB = 0;
  }
  let DeductionB =
    $80CCD1B +
    $80CCD2 +
    $80gg +
    $80d +
    $80dd +
    $80ddb +
    $80U +
    $80E +
    $80G +
    $80EEB;
  console.log("deductions :", DeductionB);
  let Aggregate10 = $80Total + DeductionB;
  let totalIncome = grossTotalIncome - Aggregate10;
  let taxOnTotalIncome = Number(TotalTaxableIncome(grossTotalIncome, Aggregate10));
  console.log("total tax :", taxOnTotalIncome);
  let Rebate = 0;
  let EducationCess = Math.floor((taxOnTotalIncome * 4) / 100);
  console.log("educationcess :", EducationCess);
  let taxPayable = Math.floor((taxOnTotalIncome + EducationCess) * 100) / 100;
  console.log("tax payable :", taxPayable);
  let reliefUser89 = totalIncome <= 500000 ? taxPayable : 0;
  let taxPayable16 = taxPayable - reliefUser89;
  let taxDeductedAtSource = 0;
  let taxDeductedByOtherEmployee = 0;
  let BalanceTaxPayable = 0;

  const userDeduction = {
    salary: salary.toString(),
    gSalaryB: gSalaryB,
    gSalaryC: gSalaryC,
    gSalaryD: gSalaryD,
    totalgross: totalgross,
    WATA: WATA,
    HRA: HRA,
    ExcPayRec: ExcPayRec,
    AllowanceTotal: AllowanceTotal,
    Balance3: Balance3,
    standardDeduction: standardDeduction,
    entertainmentAllowance: entertainmentAllowance,
    taxOnEmployment: taxOnEmployment,
    Aggregate5: Aggregate5,
    incomeChargableSalaries: incomeChargableSalaries,
    houseProperty: houseProperty,
    otherIncome: otherIncome,
    total7: total7,
    grossTotalIncome: grossTotalIncome,
    $80c: $80c.toString(),
    $80Total: $80Total,
    $80gg: $80gg,
    $80d: $80d,
    $80dd: $80dd,
    $80ddb: $80ddb,
    $80CCD1B: $80CCD1B,
    $80CCD2: $80CCD2,
    $80U: $80U,
    $80E: $80E,
    $80G: $80G,
    $80EEB: $80EEB,
    Aggregate10: Aggregate10,
    totalIncome: totalIncome,
    taxOnTotalIncome: taxOnTotalIncome,
    Rebate: Rebate,
    EducationCess: EducationCess,
    taxPayable: taxPayable,
    reliefUser89: reliefUser89,
    taxPayable16: taxPayable16,
    taxDeductedAtSource: taxDeductedAtSource,
    taxDeductedByOtherEmployee: taxDeductedByOtherEmployee,
    BalanceTaxPayable: BalanceTaxPayable,
  };
  console.log("length of the array is ", res.incomeData);

  const covertToNumber = (arr = []) => {
    let newArr = [];
    arr.forEach((item, index) => {
      newArr[index] = item.toString().split(",").join("");
    });
    return newArr;
  };

  const submitForm = async () => {
    console.log("otherEmp is ", otherEmp);
    const currentYear = year ? year : process.env.REACT_APP_CURRENT_YEAR;
    const pan = userHookData?.pan;
    const docRef = doc(db, currentYear.toString(), pan);
    try {
      await updateDoc(docRef, {
        "question.otherEmp": otherEmp,
      }, { merge: true });
      console.log("Database updated successfully");
    } catch (error) {
      console.error("Error updating database: ", error);
    }
  };


  // console.log(userHookData);
  // console.log(userData);
  return (
    <div className="container-md">
      <div className="year-box text-center">
        <img src={logo} className="app-logo" alt={t("logo")} />
        <YearSelector year={year} setYear={setYear} />
      </div>
      <div>
        <Status userPAN={userPAN} />
      </div>
      <div className="p-2 d-flex flex-column align-items-stretch">
        <marquee className="m-2 text-danger py-2">{notice}</marquee>
        {!loading ? (
          res.deductionData.map((item) => {
            if (
              res.suggestions[item.id] &&
              dispalyDeduction &&
              needForDeduction
            ) {
              dispalyDeduction = false;
              return (
                <ListItem
                  value1={t(`You are eligible for deduction for ${item.name}`)}
                  value2={item.max ? `upto \xa0 Rs. ${item.max}` : null}
                  bgcolor="green"
                  color2="highlight"
                  key={item.id}
                  navi={true}
                  disabled={true}
                  link={`/questions/${item.id}/${year}`}
                />
              );
            }
          })
        ) : (
          <div>{t("Fetching Data")}</div>
        )}
      </div>
      <div className="p-2">
        <h2>{t("Annual Income")}</h2>
        <div className="card bg-light-gray p-2">
          {!loading ? (
            <>
              <CardField
                label={t("Income from Salary")}
                value={
                  res.incomeData[0].amt - 52500 < 0
                    ? 0
                    : res.incomeData[0].amt - 52500 ?? null
                }
                key={0}
                link={`/questions/0/${year}`}
              />
              <CardField
                label={t("Income from Rent Sources")}
                value={res.incomeData[1].amt ?? null}
                key={1}
                link={`/questions/1/${year}`}
              />
              <CardField
                label={t("Income from Other Sources")}
                value={res.incomeData[2].amt ?? null}
                key={2}
                link={`/questions/3/${year}`}
              />
              {/* <div data-bs-toggle="modal" data-bs-target="#incomeEmployers"> */}
              {!loading &&
                userHookData?.Employers &&
                Array.isArray(userHookData.Employers) &&
                userHookData.Employers.length > 0 && (
                <CardField label={t("Income from Other Employers")} key={4} />
              )}
            </>
          ) : (
            <div>{t("Fetching Data")}</div>
          )}
          <div
            className="modal fade"
            id="incomeEmployers"
            style={{ marginTop: "35px" }}
            tabIndex="-1"
            aria-labelledby="incomeEmployersLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="taxPaybleLabel">
                    {t("Income from Other Employers")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body taxPayable-modal-body">
                  <div className="mt-2 d-flex justify-content-around flex-wrap">
                    <div className="quarter quater-1">
                      <div className="inner-quarter">
                        {" "}
                        Name :<span>{usersData?.name}</span>
                      </div>
                    </div>
                    <div className="quarter quater-2">
                      <div className="inner-quarter">
                      TAN Number:{" "}
                        <span>
                          {userHookData?.Employers &&
                          userHookData.Employers.length > 0
                            ? userHookData.Employers[0].tanNumber || 0
                            : 0}
                        </span>
                      </div>
                    </div>
                    <div className="quarter quater-4">
                      <div className="inner-quarter">
                        Gross Salary:{" "}
                        <input
                          type="text"
                          label="enter the Income From Other Employer"
                          value={otherEmp}
                          onChange={(e) => setotherEmp(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {t("Close")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => submitForm()}
                  >
                    {t("Submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-2">
        <h2>{t("Salary Exemption")}</h2>
        <div className="card bg-light-gray p-2">
          {!loading ? (
            <>
              <CardField
                label={t("Cost of travel on tour or transfer, maintainance of uniform")}
                value={
                  res.allowancesData[0].amt || 0
                }
                key={4}
                link={`/questions/12/${year}`}
              />
              <CardField
                label={t("Children Education allowance, Hostel allowance")}
                value={res.allowancesData[1].amt || 0}
                key={5}
                link={`/questions/13/${year}`}
              />
              <CardField
                label={t("House Rent Allowance")}
                value={res.allowancesData[2].amt || 0}
                key={6}
                link={`/questions/14/${year}`}
              />
              {/* <div data-bs-toggle="modal" data-bs-target="#incomeEmployers"> */}
              <CardField
                label={t("Travel Allowance")}
                value={res.allowancesData[3].amt || 0}
                key={7}
                link={`/questions/15/${year}`}
              />
              <CardField
                label={t("Other Allowances")}
                value={res.allowancesData[4].amt || 0}
                key={8}
                link={`/questions/16/${year}`}
              />
              {/* </div> */}
            </>
          ) : (
            <div>{t("Fetching Data")}</div>
          )}
        </div>
      </div>


      <div className="p-2">
        <h2>{t("Deductions Applicable")}</h2>
        <div className="card bg-light-gray p-2">
          {!loading ? (
            res.deductionData.map((item) => {
              return (
                (item.valid || showDeduction) &&
                !(item.id >= 0 && item.id <= 3) && (
                  <CardField
                    label={t(item.name)}
                    value={item.amt}
                    key={item.id}
                    link={`/questions/${item.id}/${year}`}
                  />
                )
              );
            })
          ) : (
            <div>{t("Fetching Data")}</div>
          )}
          <div className="d-flex flex-row-reverse px-2 pt-2">
            <ShowAllButton
              value={showDeduction ? "Show Less" : "Show All"}
              onTap={() => setShowDeduction(!showDeduction)}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <div>{t("Fetching Data")}</div>
      ) : (
        <div className="p-2 d-flex flex-column align-items-stretch">
          {/* <ListItem
            disabled={true}
            value1="Total Tax Payable:"
            value2={`Rs. ${TotalTaxableIncome(
              res.incomeData,
              res.deductionData
            )}`}
            bgcolor="orange"
            color2="highlight"
            onClick={modifyPdf}
          /> */}
          <div
            className={
              "bg-orange px-4 py-2 my-2 rounded d-flex justify-content-center align-items-center object-fit: contain"
            }
            style={{ cursor: "pointer" }}
            data-bs-toggle="modal"
            data-bs-target="#taxPayable"
          >
            <span className={"text-highlight px-1"}>
              {t("Total Tax Payable")}
            </span>
            <span className={"text-highlight px-1"}>Rs.{taxPayable16}
            </span>
          </div>
          <div
            className="modal fade"
            id="taxPayable"
            style={{ marginTop: "35px" }}
            tabIndex="-1"
            aria-labelledby="taxPayableLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="taxPayableLabel">
                    {t("Total Tax Payable")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body taxPayable-modal-body">
                  <div className="mt-2 d-flex justify-content-around flex-wrap">
                    <div className="quarter quater-1">
                      <div className="inner-quarter">
                        {" "}
                        Gross Salary : <span>₹. {userDeduction.salary}</span>
                      </div>
                    </div>
                    <div className="quarter quater-2">
                      <div className="inner-quarter">
                        Standard Deduction : <span>₹. 0</span>
                      </div>
                    </div>
                    <div className="quarter quater-3">
                      <div className="inner-quarter">
                        80C : <span> ₹. {userDeduction.$80c}</span>
                      </div>
                      <div className="inner-quarter">
                        80CCC : <span>₹. 0</span>
                      </div>
                      <div className="inner-quarter">
                        80CCD : <span>₹. {userDeduction.$80CCD1B}</span>{" "}
                      </div>
                    </div>
                    <div className="quarter quater-4">
                      <div className="inner-quarter">
                        Education Cess ( 4% ) : <span>₹. 0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {t("Close")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => modifyPdf(userObject, userDeduction)}
                  >
                    {t("Download")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "bg-light px-4 py-2 my-2 rounded d-flex justify-content-center align-items-center"
            }
            style={{ cursor: "pointer" }}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <span className={"text-dark px-1"}>{t("Total Tax Paid")}:</span>
            <span className={"text-dark px-1"}>Rs. {taxTotal} </span>
          </div>
          <div
            className="modal fade"
            id="exampleModal"
            style={{ marginTop: "35px" }}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {t("Total Tax Paid")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body taxPayable-modal-body">
                  <div className="text-center mt-2 d-flex justify-content-around flex-wrap gap-3">
                    <div className="quarter quater-1">
                      <div className="inner-quarter">
                        {" "}
                        Quarter 1 : <span>₹. {sumQ1}</span>
                      </div>
                    </div>
                    <div className="quarter quater-2">
                      <div className="inner-quarter">
                        Quarter 2 : <span>₹. {sumQ2}</span>
                      </div>
                    </div>
                    <div className="quarter quater-3">
                      <div className="inner-quarter">
                        Quarter 3 : <span>₹. {sumQ3}</span>
                      </div>
                    </div>
                    <div className="quarter quater-4">
                      <div className="inner-quarter">
                        Quarter 4 : <span>₹. {sumQ4} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {t("Close")}
                  </button>
                  {console.log(userObject)}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => PartApdf(userObject, QuarterObj)}
                  >
                    {t("Download")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ListItem
            value3={t("Send Data for Income Tax Working")}
            bgcolor={userHookData?.punch ? "blue" : "green"}
          />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
