import React, { useEffect, useState } from "react";
import logo from "./../assets/images/logo.png";
import { auth } from "./../firebase";
import { updateDoc, doc, setDoc } from "firebase/firestore";
import { db } from "./../firebase";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { getDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";

const Error = () => {
  const { t } = useTranslation();
  const [warn, setWarn] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [role, setRole] = useState("");
  var amount = 1000;
  const newWarn = (value) => {
    setWarn(value);
    setTimeout(() => {
      setWarn("");
    }, 3000);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const listen = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAuthUser(user);
        // check if all the answers are filled
        
        const match = user.email.match(/\+(.*?)@/);
        const pan = match[1].toUpperCase();

        const docRef = doc(db, "users", pan);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setRole(docSnap.data()["roleSet"]);
          if (docSnap.data()["paymentRequired"] === false) {
            return navigate("/dashboard", { replace: true });
          }
        }
      } else {
        setAuthUser(null);
        return navigate("/auth/signin", { replace: true });
      }
    });
    return () => {
      listen();
    };
  }, []);

  return (
    <center>
      <div className="error-page">
        <h1>Oops! You don&apos;t have access to this portal.</h1>
        {/* <p>We&apos;re sorry for the inconvenience.</p> */}
        <h5>Contact our sales team for assistance.</h5>
        <div>
          Contact : 7276016790
        </div>
      </div>
    </center>
  );
};
export default Error;
