import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// react-i18 next setup
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import "./index.css";

// Translator config function
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi) // passes i18n down to react-i18next
  .init({
    supportedLngs: ["en", "hi"],
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localstorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locals/{{lng}}/translation.json",
    },
  });

// Loader
const loadingMarkup = (
  <div
    className="absoluteCenter"
    style={{ display: "flex", justifyContent: "center" }}
  >
    <div className="spinner-border text-primary mt-5" role="status"></div>
  </div>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
);
