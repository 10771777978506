import React from "react";
import logo from "../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { auth } from "../firebase";
// testing if locked

export const Landing = () => {
  const navigate = useNavigate();

  // Auth user
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/dashboard", { replace: true });
      }
    });
    return () => {
      listen();
    };
  }, []);

  return (
    <>
      <div className="all-center">
        <img src={logo} className="app-logo" alt="logo" />
        <div className="box">
          {/* <button className="btn btn-signup btn-primary m-2">
            <Link to="/auth/signup">Sign Up</Link>
          </button> */}
          <button className="btn btn-login btn-primary m-2">
            <Link to="/auth/signin">Sign In</Link>
          </button>
        </div>
        {/* <div id="coursesRedirect" className="form-text mt-3">
          Access free courses <Link to="course">Here</Link>{" "}
        </div> */}
      </div>
    </>
  );
};
