import React from "react";
import { useNavigate } from "react-router-dom";
const CardField = ({ label, id, value, link }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white text-secondary rounded-pill d-flex justify-content-between py-2 px-3 m-1">
      <span className="label" style={{ alignSelf: "center" }}>
        {label}
      </span>
      <div className="right-content d-flex align-items-center">
        <span id={id} style={{ marginLeft: "5px" }}>
          ₹{value}
        </span>
        <button
          type="button"
          style={{ height: "36px", width: "40px" }}
          className="btn btn-dark bg-green d-flex justify-content-center align-items-center rounded-pill border-0 edit-button-dashboard ms-1"
          {...(label === "Income from Other Employers"
            ? {
              "data-bs-toggle": "modal",
              "data-bs-target": "#incomeEmployers",
            }
            : {})}
          onClick={() => {
            // navigate to the link
            navigate(link ?? "/");
          }}
        ></button>
      </div>
    </div>
  );
};

export default CardField;
